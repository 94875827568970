import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-product-update',
  templateUrl: './admin-product-update.component.html',
  styleUrls: ['./admin-product-update.component.css']
})
export class AdminProductUpdateComponent implements OnInit {
  complexForm: FormGroup;
  productModel = {
    id:'',
    name:'',
    price:'',
    short_description:'',
    description:'',
    image:'',
    tags:'',
    category_id:'',
    status:true,
    en_name:'',
    en_short_description:'',
    en_description:'',
    availability:1,
    outstanding:false
  };

  categoryMin = {
    max: 200,
    page: 1,
  }
  categoryList = [];
  imgeModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }
  imgesList = [];

  object = JSON.parse(JSON.stringify(this.productModel));

  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public snackBar: MatSnackBar, public loading:LoadingService, public fb: FormBuilder ) {
    this.complexForm = fb.group({
      'code': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      'price': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])],
      'categoria': [null, Validators.compose([Validators.required])],
    });
   }

  async  ngOnInit() {
     await this.getCategories();
     this.activatedRoute.params.subscribe((params: Params)=>{
       if(params['id'] == 'new'){
         this.addImgModel();
       }else{
         this.object.id = params['id'];
         this.loading.show(true,"Espere un momento...");
         this.session.postRequest("product:get",this.object).subscribe((data:any)=>{
           this.object = data.object;
           //verificamos si hay imagenes cargadas
           for(let item of this.object.images){
             let aux = JSON.parse(JSON.stringify(this.imgeModel));
             aux.uploadted = true;
             aux.img.name = item.image;
             if(aux.img.name == this.object.image){
               aux.cover = true;
             }
             this.imgesList.push(aux);
           }
           this.addImgModel();

           this.object.category_id = parseInt(this.object.category_id);
           console.log(this.imgesList);
           this.loading.hide();
         },error=>{
           console.log('product:get',error);
           this.loading.hide();
         })
       }
     },error=>{
       console.log("activatedRoute:error",error);
     })
   }

   send(exit){
     //buscamos si object tiene una imagen
     console.log(this.imgesList);
     if(this.object.image != ""){
       this.loading.show(true,"Espere un momento...");
       this.session.postRequest("product:update",this.object).subscribe(async(data:any)=>{
         this.object.id = data.object.id;
         //Recorremos las imagenes para asocialas al producto
         for(let item of this.imgesList){
           if(item.uploadted)
            await this.saveImages(item);
         }
         this.loading.hide();
         if(exit){
           this.router.navigate(['/admin/product']);
           this.snackBar.open('Registro guardado', 'Aceptar', {duration: 5000});
         }else{
         }
       },error=>{
         console.log("error:product:update",error);
         this.snackBar.open(error.message, 'Error', {duration: 5000});
         this.loading.hide();
       });
     }else{
       this.snackBar.open('Seleccione una imagen de portada', 'Aceptar', {duration: 5000});
     }
   }

   /**funcionalidad para selccionar una imagen con portada principal
   */
   coverSelected(object){
     console.log(object);
     for(let i = 0; i < this.imgesList.length; i++){
       if(object.img.name != ""){
         if(object.uploadted){
           if(this.imgesList[i].img.name == object.img.name){
             this.imgesList[i].cover = true;
             this.object.image = this.imgesList[i].img.name;
           }else{
             this.imgesList[i].cover = false;
           }
         }else{
           this.snackBar.open('Por favor suba la imagen para seleccionarla como portada.', 'Aceptar', {duration: 5000});
         }
       }else{
         this.snackBar.open('Ese campo no tiene un imagen.', 'Aceptar', {duration: 5000});
       }
     }

   }

   saveImages(object){
     return new Promise((resolve,reject)=>{
       let aux ={
         image:object.img.name,
         product:{
           id:this.object.id
         }
       }
       this.session.postRequest("product:addImage",aux).subscribe((data:any)=>{
         resolve(true);
       },error=>{
         reject(error);
       })
     });
   }


     /**función para obtener el listado de categorias
     */
     getCategories(){
       return new Promise((resolve,reject)=>{
         this.session.postRequest("category:list",this.categoryMin).subscribe((data:any)=>{
           this.categoryList = data.object.instanceList;
           if(this.categoryList.length > 0){
             this.object.category_id = this.categoryList[0].id;
           }
           resolve(true);
         },error=>{
           console.log("error:category:list",error);
           reject(error);
         })
       });

     }


     /**funcionalidad para agregar un nuevo cascaron de imagen
     */
     addImgModel(){
       this.imgesList.push(JSON.parse(JSON.stringify(this.imgeModel)));
     }

     deleteImgModel(){
       let rm = this.imgesList.pop();
       console.log(rm);
       if(rm.img.name != '' && this.object.id != ''){
         if(rm.cover){
           this.object.image = "";
         }
         let aux ={
           image:rm.img.name,
           product:{
             id:this.object.id
           }
         }
         /*funcion para eliminar una imagen de la bd
         */
         this.session.postRequest("product:deleteImage",aux).subscribe((data:any)=>{

         },error=>{
           console.log(error);
         })
       }

     }
     deleteAnImage(object){
       if(object.img.name != '' && this.object.id != ''){
         if(object.cover){
           this.object.image = "";
         }
         let aux ={
           image:object.img.name,
           product:{
             id:this.object.id
           }
         }
         /*funcion para eliminar una imagen de la bd
         */
         this.session.postRequest("product:deleteImage",aux).subscribe((data:any)=>{
           for(let i = 0; i < this.imgesList.length; i++){
             if(this.imgesList[i].img.name == object.img.name){
               this.imgesList.splice(i,1);
             }
           }
         },error=>{
           console.log(error);
         })
       }else{
         for(let i = 0; i < this.imgesList.length; i++){
           if(this.imgesList[i].img.name == object.img.name){
             this.imgesList.splice(i,1);
           }
         }
       }
     }

}
