<app-toolbar></app-toolbar>

<!-- BANNER -->
<div class="row m-0 justify-content-center">
  <div class="col-11">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
  </div>
</div>
<!--TIPOS-->

<div class="row justify-content-center" style="margin: 2rem 0rem;">
  <div class="col-md-2 col-6 rubro" style="text-align: center; cursor:pointer;" *ngFor="let object of imagesCategoy" routerLink="/productos/{{object.category.id}}">
    <img class="img-tipo"src="{{object.enabled}}" alt="{{object.name}}" *ngIf="object.seleted">
    <img class="img-tipo"src="{{object.disabled}}" alt="{{object.name}}" *ngIf="!object.seleted">
    <p class="txt-tipo">{{object.name}}</p>
  </div>
  <div class="col-md-2 col-6 rubro" style="text-align: center; cursor:pointer;" routerLink="/product/construction">
    <img class="img-tipo"src="/assets/img/home-construccion.png" alt="construccion">
    <p class="txt-tipo">CONSTRUCCIÓN</p>
  </div>
</div>

<!-- TITULO PRODUCTOS-->
<div class="row m-0">
  <div class="col-12">
    <p class="titulo text-rey"><img class="lineas" src="/assets/img/line-left-28.svg" alt=""> PRODUCTOS MÁS BUSCADOS <img class="lineas" src="/assets/img/line-right-29.svg" alt=""></p>
  </div>
</div>

<div class="container">
  <div class="row justify-content-star">
    <div class="col-lg-3 col-md-4 col-6 pl-0 mt-1" *ngFor="let object of products">
      <div class="m-0 p-0" style="background: #f2f2f2; overflow: hidden; height: 22.5rem;">
        <div class="agotadoband" *ngIf="object.availability == 0">
          AGOTADO
        </div>
        <div class="img-card col-12 p-0" style="height: 13rem; overflow: hidden; cursor:pointer;" routerLink="/product/detail/{{object.id}}" >
          <img src="{{object.image}}" alt="{{object.name}}" class="image">
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 8px 0px 0px 0px;">
          <p class="producto">{{object.name}}</p>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;">
          <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}}</h3>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;">
          <small class="text-naranja iva-incluido">IVA incluido</small>
        </div>
        <div class="row justify-content-between" style="width: 100%; margin: 0px;">
          <button class="btn-acua col-5"type="button" name="button" style="margin-bottom: 15px;" routerLink="/product/detail/{{object.id}}">ver más</button>
          <button class="btn-naranja col-5"type="button" name="button" style="margin-bottom: 15px;" (click)="addProductToShoopingCart(object)" [disabled]="object.availability == 0">
            <img src="/assets/img/icono-carrito-BLC-27.svg" alt="Carrito" width="30">
          </button>

        </div>
      </div>
    </div>
  </div>
</div>

<!--ver mas-->

<div class="row m-0 justify-content-center" style="padding: 2rem 0rem;">
  <div class="col-12" style="text-align: center;">
    <button type="button" name="button" class="btn-azul" routerLink="/productos/more">Ver todos los productos</button>
  </div>
</div>

<!-- SUSCRIPCIÓN
<div class="row" style="background: #0075bf; padding: 2rem 1rem; margin: 2rem 0px;">
  <div class="col-12">
    <div class="row m-0">
      <div class="col-3" style="text-aling: center;">
        <p style="font-family: 'Gotham-Book'; text-align: center; color: #ffcd00; margin:0px;">RECIBES NUESTRAS</p>
        <h4 style="color: #fff; text-shadow: 1px 1px 4px #2b2b2bbf; text-align: center; font-family: 'Gotham-Medium'; letter-spacing: .1rem;">Y NOVEDADES PROMOCIONES</h4>
      </div>
      <div class="col-6" style="padding: 0px; margin-top: 1rem;">
        <input class="buscador" type="text" name="" value="" placeholder="Correo electrónico">
      </div>
      <div class="col-3" style="padding: 0px 0px 0px 15px; margin-top: 1rem;">
        <button type="button" name="button" class="btn-naranja">suscribirme</button>
      </div>
    </div>
  </div>
</div> -->


<app-footer></app-footer>
