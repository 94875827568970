import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageTool } from '../../../service/session/storage.tool';
import { MatDialog } from '@angular/material/dialog';
import { WebShoppingcartDetailsComponent } from '../web-shoppingcart-details/web-shoppingcart-details.component';


@Component({
  selector: 'app-web-product-details',
  templateUrl: './web-product-details.component.html',
  styleUrls: ['./web-product-details.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class WebProductDetailsComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [{
          width: '100%',
          height: '400px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Slide
        },


        {
          breakpoint: 400,
          preview: false
        }];
  galleryImages: NgxGalleryImage[] = [];
  productModel = {
    id:'',
    name:'',
    price:'',
    short_description:'',
    description:'',
    image:'',
    tags:'',
    category_id:'',
    status:true,
    en_name:'',
    en_short_description:'',
    en_description:'',
    availability:1,
    outstanding:false
  };

  object = JSON.parse(JSON.stringify(this.productModel));
  metadata = {
    quantity:1
  }
  fakeQuantity = []
  constructor(private session: SessionService,
    private router:Router,
    public activatedRoute:ActivatedRoute,
    public snackBar: MatSnackBar,
    public loading:LoadingService,
    public storage: StorageTool,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.galleryImages = [];
    this.fakeQuantity = [];
    this.activatedRoute.params.subscribe((params: Params)=>{
      if(params['id'] == 'new'){
      }else{
        this.object.id = params['id'];
        this.loading.show(true,"Espere un momento...");
        this.session.postRequest("product:get",this.object).subscribe((data:any)=>{
          this.object = data.object;
          let images = [];
          //verificamos si hay imagenes cargadas
          for(let item of this.object.images){
            images.push({
              small:'https://store.electricamexicana.com.mx/public/uploads/'+item.image,
              medium:'https://store.electricamexicana.com.mx/public/uploads/'+item.image,
              big:'https://store.electricamexicana.com.mx/public/uploads/'+item.image,
            });
          }
          for(let i=1; i <= this.object.availability; i++){
            this.fakeQuantity.push(i);
          }
          this.galleryImages = images;
          this.loading.hide();
        },error=>{
          console.log('product:get',error);
          this.loading.hide();
        })
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /*funcionalidad para agregar un producto al carrito de compras
  */
  addProductToShoopingCart(){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      let found = false
      for(let i = 0 ; i < shoppingCart.length; i++){
        if(shoppingCart[i].product.id == this.object.id){
          found = true;
          shoppingCart[i].quantity++;
        }
      }
      if(!found){
        shoppingCart.push({
          quantity:this.metadata.quantity,
          product:this.object
        });
      }

      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      this.object.image = 'https://store.electricamexicana.com.mx/public/uploads/'+this.object.image;
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.snackBar.open('Listo, este producto se agrego a tu carrito.', 'Aceptar', {duration: 5000});
        this.viewShoopingCart(this.object);
      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

  viewShoopingCart(object){
    const dialogRef = this.dialog.open(WebShoppingcartDetailsComponent, {
      width: '50%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto
          // this.snackBar.open('No se guardo ningun registro', '', {
          //   duration: 1000
          // });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        // this.snackBar.open('No se guardo ningun registro', '', {
        //   duration: 1000
        // });
      }
    });
  }

}
