import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { StorageTool } from './../../service/session/storage.tool';
import { MatDialog } from '@angular/material/dialog';
import { WebShoppingcartDetailsComponent } from './web-shoppingcart-details/web-shoppingcart-details.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-web-home',
  templateUrl: './web-home.component.html',
  styleUrls: ['./web-home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WebHomeComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [{
          height: '400px',
          width:'100%',
          thumbnailsColumns: 4,
          "thumbnails": false,
          imageAnimation: NgxGalleryAnimation.Slide,
          "preview": false,
          imageAutoPlay: true,
          imageAutoPlayInterval: 4000,
          imageAutoPlayPauseOnHover: true,
        },
        {
          breakpoint: 400,
          preview: false
        }];
  galleryImages: NgxGalleryImage[] = [{
    small:'assets/img/Slider_Electrica-01.png',
    medium:'assets/img/Slider_Electrica-01.png',
    big:'assets/img/Slider_Electrica-01.png'
  },{
    small:'assets/img/Slider_Electrica-02.png',
    medium:'assets/img/Slider_Electrica-02.png',
    big:'assets/img/Slider_Electrica-02.png'
  },{
    small:'assets/img/52anoselmex.png',
    medium:'assets/img/52anoselmex.png',
    big:'assets/img/52anoselmex.png'
  },{
    small:'assets/img/slider_elmex_aniversario.png',
    medium:'assets/img/slider_elmex_aniversario.png',
    big:'assets/img/slider_elmex_aniversario.png'
  },{
    small:'assets/img/slider_monedero2.jpg',
    medium:'assets/img/slider_monedero2.jpg',
    big:'assets/img/slider_monedero2.jpg'
  },{
    small:'assets/img/slider2.jpg',
    medium:'assets/img/slider2.jpg',
    big:'assets/img/slider2.jpg'
  }];
  imagesCategoy = [{
    name:'ELECTRICIDAD',
    enabled:'/assets/img/home-electricidad.png',
    disabled:'/assets/img/home-electricidad.png',
    category:{id:''},
    seleted:false
  },{
    name:'PLOMERÍA',
    enabled:'/assets/img/home-plomeria.png',
    disabled:'/assets/img/home-plomeria.png',
    category:{id:''},
    seleted:false
  },{
    name:'ILUMINACIÓN',
    enabled:'/assets/img/home-iluminacion.png',
    disabled:'/assets/img/home-iluminacion.png',
    category:{id:''},
    seleted:false
  },{
    name:'HERRAMIENTA',
    enabled:'/assets/img/home-herramientas.png',
    disabled:'/assets/img/home-herramientas.png',
    category:{id:''},
    seleted:false
  }];

  categoryMin = {
    max: 100,
    page: 1,
  }
  productMin ={
    status: true,
    outstanding:true,
    max:20,
    page:1
  }
  categories = [];
  products = [];

  constructor(private session:SessionService, public loading:LoadingService,
    public storage: StorageTool,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getCategories();
    this.getProducts();
  }

  /**función para obtener el listado de categorias
  */
  getCategories(){
    this.session.postRequestAnonimus("category:list",this.categoryMin).subscribe((data:any)=>{
      this.categories = data.object.instanceList;
      for(let item of this.categories){
        for(let i = 0; i < this.imagesCategoy.length; i++){
          if(item.name == this.imagesCategoy[i].name){
            this.imagesCategoy[i].category = item;
          }
        }
      }
    },error=>{
      console.log("error:category:list",error);
    })
  }

  /**funcionalidad para obtener el lsuatdo de productos
  */
  getProducts(){
    this.loading.show(true,"Espere un momento...")
    this.session.postRequestAnonimus("product:findAllByStatusAndOutstanding",this.productMin).subscribe((data:any)=>{
      // console.log(data);
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].image = 'https://store.electricamexicana.com.mx/public/uploads/'+data.object.instanceList[i].image;
      }
      this.products = data.object.instanceList;
      this.loading.hide();
    },error=>{
      console.log("product:findAllByStatusAndOutstanding:error",error);
      this.loading.hide();
    })
  }


  /*funcionalidad para agregar un producto al carrito de compras
  */
  addProductToShoopingCart(object){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      let found = false
      for(let i = 0 ; i < shoppingCart.length; i++){
        if(shoppingCart[i].product.id == object.id){
          found = true;
          shoppingCart[i].quantity++;
        }
      }
      if(!found){
        shoppingCart.push({
          quantity:1,
          product:object
        });
      }

      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.snackBar.open('Listo, este producto se agrego a tu carrito.', 'Aceptar', {duration: 5000});
        this.viewShoopingCart(object);
      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

  viewShoopingCart(object){
    const dialogRef = this.dialog.open(WebShoppingcartDetailsComponent, {
      width: '50%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto
          // this.snackBar.open('No se guardo ningun registro', '', {
          //   duration: 1000
          // });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        // this.snackBar.open('No se guardo ningun registro', '', {
        //   duration: 1000
        // });
      }
    });
  }

}
