import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';

import { WebHomeComponent } from './web/web-home/web-home.component';
import { WebProductDetailsComponent } from './web/web-home/web-product-details/web-product-details.component';
import { WebShoppingcartComponent } from './web/web-home/web-shoppingcart/web-shoppingcart.component';
import { WebProductosComponent } from './web/web-home/web-productos/web-productos.component';
import { WebPaymentComponent } from './web/web-home/web-payment/web-payment.component';
import { WebSearchMexpagoComponent } from './web/web-home/web-search-mexpago/web-search-mexpago.component';
import { WebTrackOrderComponent } from './web/web-home/web-track-order/web-track-order.component';

import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { AdminSalesComponent } from './admin/admin-sales/admin-sales.component';
import { WebPayworksStatusComponent } from './web/web-home/web-payworks-status/web-payworks-status.component';
import { WebSendPoliticsComponent } from './web/web-home/web-send-politics/web-send-politics.component';
import { WebTermsComponent } from './web/web-home/web-terms/web-terms.component';
import { ProductConstructionComponent } from './web/web-home/product-construction/product-construction.component';
import { AdminSalesContracargoComponent } from './admin/admin-sales-contracargo/admin-sales-contracargo.component';
import { AdminSalesSucursalComponent } from './admin/admin-sales-sucursal/admin-sales-sucursal.component';
import { AdminPvBanorteComponent } from './admin/admin-pv-banorte/admin-pv-banorte.component';
import { AdminPvBanorteDetailsComponent } from './admin/admin-pv-banorte/admin-pv-banorte-details/admin-pv-banorte-details.component';

const routes: Routes = [
  { path :'' ,redirectTo:'home' , pathMatch:'full' },
  { path:'home',component: WebHomeComponent },
  { path:'product/detail/:id',component: WebProductDetailsComponent },
  { path:'cart',component: WebShoppingcartComponent },
  { path:'productos/:id',component: WebProductosComponent },
  { path:'product/construction',component: ProductConstructionComponent },
  { path:'payment',component:WebPaymentComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS'] } },
  { path:'client/search/:phone',component:WebSearchMexpagoComponent },
  { path:'client/track/:data',component:WebTrackOrderComponent },
  { path:'payworks/:data',component:WebPayworksStatusComponent },
  { path:'admin/login', component: AdminLoginComponent  },
  { path:'admin/dashboard', component: AdminSalesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/sales/branch', component: AdminSalesSucursalComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/delivery', component: AdminDeliveryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/category', component: AdminCategoryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/product', component: AdminProductComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/product/update/:id', component: AdminProductUpdateComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/sales', component: AdminSalesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/pv', component: AdminPvBanorteComponent },
  { path:'admin/pv/details/:data', component: AdminPvBanorteDetailsComponent },
  { path:'shipping_policies', component: WebSendPoliticsComponent },
  { path:'terms', component: WebTermsComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
