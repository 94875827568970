<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Cobro por tarjeta</h2>
      </div>
      <div class="col-3 text-right">
      </div>
    </div>

    <div class="row justify-content-center">
      <p class="col-12">Ingrese los datos requeridos del formulario.</p>

      <div class="col-8 row">
        <mat-form-field appearance="fill" class="col-8 col-md-4">
          <mat-label>Sucursal</mat-label>
          <mat-select placeholder="Sucursal" name="Sucursal" [(ngModel)]="object.shipping.street" required>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let object of shipinsList" [value]="object.street" (click)="shippingSelected(object);">
              {{object.delivery}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-2 col-md-4">
          <mat-label>Cantidad</mat-label>
          <input matInput placeholder="Cantidad" name="price" [formControl]="payworksForm.controls['price']" [(ngModel)]="object.saleProducts[0].unit_price" required>
          <mat-hint>
            <mat-error *ngIf="payworksForm.controls['price'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-hint>
        </mat-form-field>

        <div>
          <mat-form-field class="col-12" appearance="fill" >
            <mat-label>Nombre del Titular</mat-label>
            <input matInput placeholder="Nombre del Titular" name="name" [(ngModel)]="metadata.name" [formControl]="payworksForm.controls['name']" required>
            <mat-error *ngIf="payworksForm.controls['name'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12" appearance="fill" >
            <mat-label>Numero de Tarjeta</mat-label>
            <input matInput placeholder="Numero de Tarjeta" name="cardNumber" [(ngModel)]="payworksModel.NUMERO_TARJETA" [formControl]="payworksForm.controls['cardNumber']" required>
            <mat-error *ngIf="payworksForm.controls['cardNumber'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-6" appearance="fill">
            <mat-label>Mes</mat-label>
            <mat-select placeholder="Mes" name="exp_month" [(ngModel)]="metadata.exp_month" [formControl]="payworksForm.controls['exp_month']"  required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let object of metadata.months" [value]="object.value">
                {{object.month}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="payworksForm.controls['exp_month'].hasError('required')">Campo <strong>requerido</strong>.</mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-6" appearance="fill">
            <mat-label>Año</mat-label>
            <mat-select placeholder="Año" name="exp_year" [(ngModel)]="metadata.exp_year" [formControl]="payworksForm.controls['exp_year']"  required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let object of metadata.years" [value]="object">
                {{object}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="payworksForm.controls['exp_year'].hasError('required')">Campo <strong>requerido</strong>.</mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-4 col-6">
            <mat-label>CVC</mat-label>
            <input matInput placeholder="CVC" name="cvcNumber" [(ngModel)]="payworksModel.CODIGO_SEGURIDAD" [formControl]="payworksForm.controls['cvc']" required >
            <span matSuffix><i class="material-icons">credit_card</i></span>
            <mat-error *ngIf="payworksForm.controls['cvc'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>

        </div>
        <button mat-flat-button color="accent"  class="col-12 buttonPayment" [disabled]="payworksForm.invalid" (click)="sendTo3DSecure();">COBRAR</button>

      </div>



    </div>


  </div>
</div>
