<app-toolbar></app-toolbar>

<div class="row justify-content-center" style="text-align: justify;">
  <div class="col-12 col-md-8">
    <h2>Políticas de envío</h2>
    <p>Eléctrica Mexicana SA de CV  trabaja continuamente en la mejora de su servicio. El objetivo es que desde la comodidad de su hogar adquiera productos también en la tienda en línea y pueda disfrutar de su compra en el menor tiempo posible.</p>
    <p>A continuación se presentan los tiempos máximos de envío considerando un radio de 10km a la redonda del centro de la Ciudad de Oaxaca, mismos que aplican una vez que se haya confirmado el pago en la tienda en línea <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a>/:</p>


<br>
<h3>Entrega en sucursal</h3>
<ul>
  <li>Las compras realizadas y confirmadas de lunes a sábado en un horario de 08:00 a 13:00 hrs podrán recogerse después de 3 horas después de su compra en cualquiera de nuestras sucursales.</li>
  <li>Las compras realizadas y confirmadas de lunes a sábado en un horario de 13:01 a 18:00 hrs podrán recogerse a partir de las 09:00 hrs del día siguiente  en cualquiera de nuestras sucursales. </li>
  <li>Las compras realizadas y confirmadas después de las 18:00hrs de lunes a sábado, domingos y/o días festivos podrán recogerse a partir de las 12:00 hrs del día hábil posterior a la fecha de compra.</li>
</ul>
<br>
<h3>Servicio a domicilio</h3>

<ul>
  <li>Las compras realizadas y confirmadas de lunes a sábado en un horario de 08:00 a 12:00 hrs serán programadas para su entrega el mismo día en un horario de 15:00 a 18:00hrs</li>
  <li>Las compras realizadas y confirmadas de lunes a sábado después de las 12:01 hrs serán programadas para su entrega al siguiente día hábil a partir de las 09:00 hrs.</li>
</ul>
<br>

<h4>COMPRA MÍNIMA</h4>

<ul>
  <li>El monto mínimo de compra para entrega en domicilio sin costo es de $1,000.00 IVA incluido (Un mil pesos 00/100 MN)</li>
  <li>En caso de solicitar el servicio a domicilio y no completar el monto mínimo de compra, el envío tendrá un costo de $80.00 (Ochenta pesos 00/100 MN)</li>
</ul>
<br>
<p>*Para envíos al interior del estado u otros estados, es necesario comunicarse al teléfono 951 226 29 78  para cotizar su compra y los gastos de envíos. </p>
<br>
<h4>MÉTODOS DE PAGO</h4>

<p>En la tienda en línea <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a>/ se aceptan los siguientes métodos de pago:</p>
<ul>
  <li>Tarjeta de débito</li>
  <li>Tarjeta de crédito</li>
  <li>Cobro contra entrega</li>
  <li>*Crédito mejoravit</li>
</ul>


<p>Su compra en <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a> es totalmente segura. Le pedimos que antes de firmar la recepción de su producto, revise que se encuentre completo y en buen estado. </p>
<p>Es importante registrar un número telefónico de contacto ya que antes de entregar su compra, nos comunicaremos con usted para informarle. Le recomendamos estar pendiente ya que de lo contrario su paquete puede ser retornado y el reenvío tendría un costo de $80.00 (Ochenta pesos 00/100 MN)</p>
<p>Eléctrica Mexicana SA de CV se reserva el derecho de modificar estas políticas sin previo aviso.</p>


  </div>
</div>

<app-footer></app-footer>
