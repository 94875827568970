<div class="row p-2 m-0" [ngClass]="{'menuFixed' : metadata.menuFixed}" #menu style="height: 12vh; background: white;">
  <div class="col-2 logo" style="height: 100%;" routerLink="/admin/dashboard">
    <img src="/assets/img/logo.png" style="height: 100%; padding: 5px;">
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;">
    <ul class="font-platino menu" data-animation="center">
      <li class="horizontal"><a routerLink="/admin/pv">COBRO POR TARJETA</a></li>
      <li class="horizontal"><a routerLink="/admin/sales/branch">PEDIDOS A RECOGER EN TIENDA</a></li>
      <li class="horizontal"><a routerLink="/admin/sales">PEDIDOS A DOMICILIO</a></li>
      <li class="horizontal"><a routerLink="/admin/product">PRODUCTO</a></li>
      <li class="horizontal"><a routerLink="/admin/category">CATEGORIAS</a></li>
      <li class="horizontal"><a routerLink="/admin/delivery">MENSAJERIA</a></li>
      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
</div>
