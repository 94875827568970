<!-- <div class="alert alert-success" role="alert" *ngIf=" object.typePayment == 'Payworks'"> Pagado </div>
<div class="alert alert-danger" role="alert" *ngIf=" object.typePayment == 'Contra Entrega'">Pago pendiente</div> -->

<p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Acontinuación se detallan los datos de la compra.</p>
<ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
  <li>Folio: {{object.folio}}</li>
  <li>Cliente: {{object.clientName}}</li>
  <li>Correo: {{object.clientEmail}}</li>
  <li>Teléfono: {{object.clientPhone}}</li>
</ul>
<p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Datos de envio</p>
<ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
  <li>Direccion: {{object.shipping.street + ', '+object.shipping.colony + ', '+object.shipping.city}}</li>
  <li>Estado: {{object.shipping.state}}</li>
  <li>Código Postal: {{object.shipping.cp}}</li>
  <li>Mensajeria: {{object.shipping.delivery}}</li>
</ul>
<div *ngIf="object.clientSocialReason != ''" style="background: #d6d6d6;border-radius: 5px;padding: 5px;">
  <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;" *ngIf="object.clientSocialReason != ''">Datos de facturación*</p>
  <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;" *ngIf="object.clientSocialReason != ''">
    <li>Razón Social: {{object.clientSocialReason}}</li>
    <li>RFC: {{object.clientRfc}}</li>
    <li>Email: {{object.clientEmail}}</li>
  </ul>
  <p style="color: #045081; font-size: small;">*El cliente requiere factura.</p>
</div>
<div class="row">
  <div class="col-12">
    <p>Complete el formulario y/o cambie el estatus de la venta.</p>
  </div>
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>&nbsp;Guia de envio</mat-label>
    <input matInput placeholder="Guia de envio" name="guide" [(ngModel)]="order.guide">
  </mat-form-field>
  <mat-form-field class="col-12" appearance="outline">
    <mat-label>&nbsp;Observaciones</mat-label>
    <textarea matInput placeholder="Descripción" name="mdescription" [(ngModel)]="order.observations"></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-12">
    <mat-label>Cambiar estatus de pedido</mat-label>
    <mat-select name="mstatusSel" [(ngModel)]="order.status" (ngModelChange)="orderChangeStatus();">
      <mat-option *ngFor="let object of statusList" [value]="object">{{object}}</mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div class="row">
  <div class="col-12">
    <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Listado de productos.</p>
    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Imagen</th>
          <th>Nombre</th>
          <th>Cantidad</th>
          <th>Precio Unit.</th>
          <th>Total</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of saleProductsList; let i = index;">
          <td>
            <div class="imgAvatar">
              <img src="{{object.product.image}}" alt="{{object.product.name}}">
            </div>
          </td>
          <td>{{object.product.name}}</td>
          <td>
            {{object.quantity}}
          </td>
          <td>
            {{object.unit_price | currency:'MXN':'symbol-narrow'}}
          </td>
          <td>
            {{object.total | currency:'MXN':'symbol-narrow'}}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
      <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
        <div class="imgTitle">
          <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #3ab77c; color: #fff; font-weight: 100; font-size: 0.83rem; top: 5px; left:5px;">{{object.quantity}}</span>
          <div class="imgAvatar">
            <img src="{{object.product.image}}" alt="{{object.product.name}}">
          </div>
        </div>
        <div class="titlePayment">
          {{object.product.name}}
        </div>
        <div class="priceTitle">
          {{object.total | currency:'MXN':'symbol-narrow'}}
        </div>
      </li>
    </ul> -->
    <hr>

  </div>
  <div class="col-6">

  </div>
  <div class="col-3" style="margin: 0px; padding: 0px 25px;">
    <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
      <li style="margin-bottom: 15px;">ENVÍO</li>
      <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
    </ul>
  </div>
  <div class="col-3" style="margin: 0px; padding: 0px 15px; text-align: end;">
    <ul  style="list-style:none; padding: 0px;">
      <li style="margin-bottom: 15px;">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
      <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
    </ul>
  </div>
</div>
<div class="row justify-content-end mt-4">
  <div class="col-4">
    <button mat-flat-button color="warn" style="width:100%;"  (click)="close(true);">Aceptar</button>
    <br>
  </div>
</div>
