import { Component, OnInit } from '@angular/core';
import { StorageTool } from '../../../service/session/storage.tool';
import {Location} from '@angular/common';

@Component({
  selector: 'app-web-shoppingcart',
  templateUrl: './web-shoppingcart.component.html',
  styleUrls: ['./web-shoppingcart.component.css']
})
export class WebShoppingcartComponent implements OnInit {

  shoopingCart = [];
  metadata = {
    subtotal:0,
    language:'spanish'
  }
  constructor(public storage: StorageTool,private _location: Location) {
    this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
      if(data){
        this.metadata.language = data;
      }
    })
  }

  ngOnInit(): void {
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      if(data){
        this.shoopingCart = data;
        this.calculateSubtotal();
        // console.log(this.shoopingCart)
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad parcular el subtotal y pasar a pagar
  */
  calculateSubtotal(){
    this.metadata.subtotal = 0;
    for(let item of this.shoopingCart){
      this.metadata.subtotal = this.metadata.subtotal + (item.quantity*item.product.price);
    }
  }

  /**funcionalidad eliminar un producto del carrito de compras
  */
  deleteAProduct(object){
    for(let i = 0; i < this.shoopingCart.length; i++){
      if(object.product.id == this.shoopingCart[i].product.id){
        this.shoopingCart.splice(i,1);
      }
    }
    this.calculateSubtotal();
    localStorage.setItem('carritoLength', this.shoopingCart.length.toString());
    this.storage.saveAKeyOnTheStorage('shoppingCart',this.shoopingCart).then(()=>{

    }).catch(e=>{
      console.log(e);
    })
  }

  backClicked() {
  this._location.back();
}

/**funcionalidad para cambiar cantidad de una producto
*/
async changeQuantity(status,object){
  if(status == 'sumar'){
    object.quantity++;
  }else{
    object.quantity--;
  }
  if(object.quantity <= 0){
    object.quantity = 1;
  }
  for(let i = 0; i < this.shoopingCart.length; i++){
    if(object.product.id == this.shoopingCart[i].product.id){
      this.shoopingCart[i] = object;
    }
  }
  this.calculateSubtotal();
  this.storage.saveAKeyOnTheStorage('shoppingCart',this.shoopingCart).then(()=>{
  }).catch(e=>{
    console.log(e);
  })
}

}
