<app-toolbar></app-toolbar>

<!-- TITULO -->
<div class="row justify-content-center m-0" style="" *ngIf= "!metadata.payworks">
    <h2 style="padding-top: 35px; font-size: 1.5rem;">Gracias por tu preferencia.</h2>
</div>
<div class="row justify-content-center m-0" style="padding: 20px 0px;" *ngIf= "!metadata.payworks">
  <div class="col-11 col-md-10 col-lg-10">
    <div style="padding: 10px; padding-top: 0px;">
      <mat-card style="background-color: #fdfdfd;">
        <div class="alert alert-success" role="alert" *ngIf=" object.status == 'Pagado'"> Pagado </div>
        <div class="alert alert-danger" role="alert" *ngIf=" object.status == 'Pendiente'">Pago pendiente</div>
        <!-- <p>Acontinuación se detallan los datos de tu compra.</p> -->
        <div style=" margin:auto; width:50%;">
          <button type="button" name="button" class="btn-azul doNotPrint mr-1" (click)="onPrint();" style="margin: 15px 0px; ">Imprimir</button>
          <button type="button" name="button" class="btn-azul doNotPrint" (click)="downloadImage();" style="margin: 15px 0px;">Descargar</button>

          <div *ngIf="object.deliverytext != 'Entrega en domicilio'" style="background: #d6d6d6;border-radius: 5px;padding: 5px;padding-bottom: 30px;text-align: center;width: 100%;" id="printTicket">
      <h4>Hola {{object.clientName}}</h4>

            <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;"> Recoge tu pedido en tienda. </p>
            <p style="color: #045081; margin:0px; font-size: larger;">Folio: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.folio}}</span></p>
            <p style="color: #045081; margin:0px;">Nombre: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.clientName}}</span></p>
            <p style="color: #045081; margin:0px;">Sucursal: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.deliverytext}}</span></p>

            <p style="color: #045081; margin:0px;font-weight: bold; margin-top: 30px;"> {{object.typePayment == 'Contra Entrega' ? 'Paga al recoger en tienda':'Pagado con Tarjeta de Débito / Crédito'}} </p>
            <div class="row justify-content-center">
              <div class="col-11">
                <button type="button" name="button" class="btn-naranja" (click)="finished();" style="margin: 15px 0px;">FINALIZAR</button>

                <hr>
                <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Listado de productos.</p>
                <ul style="list-style:none; font-size:10pt; color:#6f6f6f;">
                  <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
                    <div class="imgTitle">
                      <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #3ab77c; color: #fff; font-weight: 100; font-size: 0.83rem; top: 5px; left:5px;">{{object.quantity}}</span>
                      <div class="imgAvatar" *ngIf="object.product">
                        <img src="{{object.product.image}}" alt="{{object.product.name}}">
                      </div>
                    </div>
                    <div class="titlePayment" *ngIf="object.product">
                      {{object.product.name}}
                    </div>
                    <div class="priceTitle">
                      {{object.total | currency:'MXN':'symbol-narrow'}}
                    </div>
                  </li>
                </ul>
                <hr>

              </div>
              <div class="col-6" style="margin: 0px; padding: 0px 25px;">
                <ul style="list-style:none; font-size:10pt; color:#6f6f6f;">
                  <li style="margin-bottom: 15px;">ENVÍO</li>
                  <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
                </ul>
              </div>
              <div class="col-5" style="margin: 0px; padding: 0px 15px; text-align: end;">
                <ul  style="list-style:none;">
                  <li style="margin-bottom: 15px;">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
                  <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
                </ul>
              </div>
            </div>
          </div>

          <div *ngIf="object.deliverytext == 'Entrega en domicilio'  " style="background: #d6d6d6;
          border-radius: 5px;
          padding: 5px;
          padding-bottom: 30px;
          text-align: center;
          width: 100%;
      "
      id="printTicket"
      >
            <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;"> Tu pedido será entregado a domicilio. </p>
            <p style="color: #045081; margin:0px; font-size: larger;">Folio: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.folio}}</span></p>
            <p style="color: #045081; margin:0px;">Nombre: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.clientName}}</span></p>
            <p style="color: #045081; margin:0px;">Teléfono: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.clientPhone}}</span></p>
            <p style="color: #045081; margin:0px;">Correo: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;">{{object.clientEmail}}</span></p>
            <p style="color: #045081; margin:0px;">Domicilio: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;"> {{object.shipping.street + ', '+object.shipping.colony + ', '+object.shipping.city}} </span></p>
            <p style="color: #045081; margin:0px;">Estado: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;"> {{object.shipping.state}} </span></p>
            <p style="color: #045081; margin:0px;">Código Postal: <span style="color: #0075bf;padding-top: 15px;font-weight: bold;"> {{object.shipping.cp}} </span></p>

            <p style="color: #045081; margin:0px;font-weight: bold; margin-top: 30px;"> {{object.typePayment == 'Contra Entrega' ? 'Paga tu pedido cuando llegue a domicilio ':'Pagado con Tarjeta de Débito / Crédito'}} </p>

            <div class="row justify-content-center">
              <div class="col-11">

                <button type="button" name="button" class="btn-naranja" (click)="finished();" style="margin: 15px 0px;">FINALIZAR</button>

                <hr>
                <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Listado de productos.</p>
                <ul style="list-style:none; font-size:10pt; color:#6f6f6f;">
                  <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
                    <div class="imgTitle">
                      <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #3ab77c; color: #fff; font-weight: 100; font-size: 0.83rem; top: 5px; left:5px;">{{object.quantity}}</span>
                      <div class="imgAvatar" *ngIf="object.product">
                        <img src="{{object.product.image}}" alt="{{object.product.name}}">
                      </div>
                    </div>
                    <div class="titlePayment" *ngIf="object.product">
                      {{object.product.name}}
                    </div>
                    <div class="priceTitle">
                      {{object.total | currency:'MXN':'symbol-narrow'}}
                    </div>
                  </li>
                </ul>
                <hr>

              </div>
              <div class="col-6" style="margin: 0px; padding: 0px 25px;">
                <ul style="list-style:none; font-size:10pt; color:#6f6f6f;">
                  <li style="margin-bottom: 15px;">ENVÍO</li>
                  <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
                </ul>
              </div>
              <div class="col-5" style="margin: 0px; padding: 0px 15px; text-align: end;">
                <ul  style="list-style:none;">
                  <li style="margin-bottom: 15px;">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
                  <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
                </ul>
              </div>
            </div>

          </div>

        </div>






        <!-- <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
          <li>Folio: {{object.folio}}</li>
          <li>Cliente: {{object.clientName}}</li>
          <li>Correo: {{object.clientEmail}}</li>
          <li>Teléfono: {{object.clientPhone}}</li>
          <li>Medio de Pago: {{object.typePayment == 'Contra Entrega' ? 'Contra Entrega':'Tarjeta de Débito / Crédito'}}</li>
        </ul> -->

      </mat-card>
    </div>
  </div>
  <div class="col-11 col-md-10 col-lg-10">


  </div>
</div>
<div class="row justify-content-center m-0" style="padding: 20px 0px;" *ngIf= "metadata.payworks">
  <div class="col-11 col-md-10 col-lg-10">
    <div style="padding: 10px; padding-top: 0px;">
      <mat-card style="background-color: #fdfdfd;">
        <div class="alert alert-danger" role="alert">
          <p>Hubo un Error: {{metadata.message}}</p>
          Código de error: {{metadata.control}}
        </div>
      </mat-card>
    </div>
  </div>
  <div class="col-11 col-md-10 col-lg-10">


  </div>
</div>




<app-footer></app-footer>
