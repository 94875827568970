import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-factura',
  templateUrl: './add-factura.component.html',
  styleUrls: ['./add-factura.component.css']
})
export class AddFacturaComponent implements OnInit {
  firstStepForm: FormGroup;
  sale = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: false,
    privacy:false,
    emailfactura:'',
    user:{
      id:0
    },
    shipping:{
      id:''
    },
    saleProducts:[]
  };
  metadata = {
    factura:'No'
  }

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public dialogRef: MatDialogRef<AddFacturaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar:MatSnackBar) {
    this.firstStepForm = fb.group({
      'factura': [null, Validators.required]
    })
    this.sale = this.data;
  }

  ngOnInit(): void {

  }


  facturaSelected(){
  }

  send(){
    this.loadingService.show(true,'Guardando registro...');
    if(this.metadata.factura == 'No'){
      this.sale.emailfactura =  this.sale.clientEmail;
    }
    this.session.postRequest("sale:updateFactura",this.sale).subscribe(
      (data:any) => {
        this.snackBar.open('Al concluir tu compra, se te enviará por correo electrónico tu factura; Gracias por tu preferencia', '', {
          duration: 11000
        });
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: category:update",error);
        this.loadingService.hide();
      }
    );
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.sale;
    }else{
      object.transaction = 'bad';
      object.code = 'category:001';
    }
    this.dialogRef.close(object);
  }

}
