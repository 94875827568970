<app-toolbar></app-toolbar>


<!--TIPOS-->

<div class="row justify-content-center" style="margin: 2rem 0rem;">
  <div class="col-2 rubro" style="text-align: center; cursor:pointer;" *ngFor="let object of imagesCategoy" (click)="categorySelected(object.category.id);">
    <img class="img-tipo"src="{{object.enabled}}" alt="{{object.name}}" *ngIf="object.seleted">
    <img class="img-tipo"src="{{object.disabled}}" alt="{{object.name}}" *ngIf="!object.seleted">
    <p class="txt-tipo">{{object.name}}</p>
  </div>
  <div class="col-md-2 col-6 rubro" style="text-align: center; cursor:pointer;" routerLink="/product/construction">
    <img class="img-tipo"src="/assets/img/home-construccion.png" alt="construccion">
    <p class="txt-tipo">CONSTRUCCIÓN</p>
  </div>
</div>

<!--PRODUCTOS CARDS-->
<div class="container">
  <div class="row justify-content-star">
    <div class="col-lg-3 col-md-4 col-6 pl-0 mt-1" style="padding: 2rem 1rem;" *ngFor="let object of list">
      <div class="col-12 container m-0 p-0" style="background: #f2f2f2; overflow: hidden;">
        <div class="agotadoband" *ngIf="object.availability == 0">
          AGOTADO
        </div>
        <div class="img-card col-12 p-0" style="height: 13rem; overflow: hidden; cursor:pointer;" routerLink="/product/detail/{{object.id}}" >
          <img src="{{object.image}}" alt="{{object.name}}" class="image">
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 8px 0px 0px 0px;">
          <p class="producto">{{object.name}}</p>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;">
          <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}}</h3>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;">
          <small class="text-naranja iva-incluido">IVA incluido</small>
        </div>
        <div class="row justify-content-between" style="width: 100%; margin: 0px;">
          <button class="btn-acua col-5"type="button" name="button" style="margin-bottom: 15px;" routerLink="/product/detail/{{object.id}}">ver más</button>
          <button class="btn-naranja col-5"type="button" name="button" style="margin-bottom: 15px;" (click)="addProductToShoopingCart(object)" [disabled]="object.availability == 0">
            <img src="/assets/img/icono-carrito-BLC-27.svg" alt="Carrito" width="30">
          </button>

        </div>
      </div>
    </div>
    <!--aca termina la tarjeta-->
  </div>
</div>

<app-footer></app-footer>
