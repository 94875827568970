import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-delivery-update',
  templateUrl: './admin-delivery-update.component.html',
  styleUrls: ['./admin-delivery-update.component.css']
})
export class AdminDeliveryUpdateComponent implements OnInit {
  complexForm:FormGroup;
  deliveryModel = {
    id:'',
    name:'',
    time:'--',
    tags:'',
    status:true,
    price:'',
    pickup:false
  }

  object = JSON.parse(JSON.stringify(this.deliveryModel));

  deliveryPoliticModel = {
    id:'',
    politic:'',
    delivery:{
      id:''
    }
  }
  deliveryPoliticList = [];
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminDeliveryUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'name': [null, Validators.required],
      'price': [null, Validators.required],
    })
  }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
       // cargamos los datos de la BD
       this.session.postRequest('delivery:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.getPoliticsByDeliver();
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: delivery:get',error)
       })
    }
  }

  /**
   * funcion para guardar una unidades de medida
   */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("delivery:update",object).subscribe(
      async(data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        for(let item of this.deliveryPoliticList){
          item.delivery.id = data.object.id;
          await this.savePolitic(item);
        }
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: delivery:update",error);
        this.loadingService.hide();
      }
    );
  }

  /**funcionalidad para agregar un deliveryPolitic
  */
  addPolitic(){
    let aux = JSON.parse(JSON.stringify(this.deliveryPoliticModel));
    this.deliveryPoliticList.push(aux);
  }

  /**funcionalidad para obtener las politicas de envios por delivery
  */
  getPoliticsByDeliver(){
    this.session.postRequestAnonimus("deliveryPolitic:findAllByDelivery",{delivery:{id:this.object.id}}).subscribe((data:any)=>{
      this.deliveryPoliticList = data.object.instanceList;
      this.addPolitic();
    },error=>{
      this.loadingService.hide();
      console.log(error);
    })
  }

  /**funcionalidad para agregar una politica de envio
  */
  savePolitic(object){

    return new Promise((resolve,reject)=>{
      if(object.politic != ''){
        this.session.postRequest("deliveryPolitic:update",object).subscribe((data:any)=>{
          object.id = data.object.id;
          resolve(true);
        },error=>{
          console.log(error);
          reject(error);
        });
      }else{
        resolve(true);
      }

    });
  }


  /**funcionalidad para eliminar una politica
  */
  deleteAPolitic(object){
    this.loadingService.show(true,"Espere un momento...");
    if(object.id == ''){
      for(let i = 0; i < this.deliveryPoliticList.length; i++){
        if(this.deliveryPoliticList[i].politic == object.politic){
          this.deliveryPoliticList.splice(i,1);
        }
      }
      this.loadingService.hide();
    }else{
      this.session.postRequest("deliveryPolitic:delete",object).subscribe((data:any)=>{
        for(let i = 0; i < this.deliveryPoliticList.length; i++){
          if(this.deliveryPoliticList[i].id == object.id){
            this.deliveryPoliticList.splice(i,1);
          }
        }
        this.loadingService.hide();
      },error=>{
        this.loadingService.hide();
      })
    }
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'delivery:001';
    }
    this.dialogRef.close(object);
  }

}
