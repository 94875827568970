import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../directive/loading/loading.module';
import { SessionService } from '../../service/session/session.module';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminSalesSucursalDetailsComponent } from './admin-sales-sucursal-details/admin-sales-sucursal-details.component';
import * as moment from 'moment';
moment.locale('es-us');
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-sales-sucursal',
  templateUrl: './admin-sales-sucursal.component.html',
  styleUrls: ['./admin-sales-sucursal.component.css']
})
export class AdminSalesSucursalComponent implements OnInit {

  saleMin = {
    status:'Pagado', //Pagado | Pendiente
    typePayment:'Payworks',
    delivery:'sucursal',
    filter:'',
    max:25,
    page: 1,
    orderStatus:''
  }
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    searching:false,
    colorPagado:'accent',
    colorContra:'default'
  }

  saleList = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;

  deliveryMin = {
    max: 100,
    page: 1,
  }

  deliverylist = [];
  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.saleList = [];
          // this.getBookingSearching();
        } else {
          this.saleList = [];
          this.saleMin.filter = '';
          // this.getBooking();
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        console.log(error);
      });
  }

  ngOnInit(): void {
    this.saleMin.status = 'Pagado';
    this.saleMin.orderStatus = 'Nuevo';
    this.getDeliveries();
  }

  /**función para obtener el listado de categorias
  */
  getDeliveries(){
    this.session.postRequest("delivery:list",this.deliveryMin).subscribe((data:any)=>{
      for(let item of data.object.instanceList){
        if(item.pickup){
          this.deliverylist.push(item);
        }
      }
      if(this.deliverylist.length > 0){
        this.saleMin.delivery = this.deliverylist[0].name
      }
      this.getSalesByOrder();
    },error=>{
      console.log("error:delivery:list",error);
    })
  }


  chipSelected(object){
    console.log(this.metadata);
    if(object == 'Pagado'){
      this.saleMin.status = 'Pagado';
      this.saleMin.typePayment = 'Payworks';
      this.metadata.colorPagado = 'accent';
      this.metadata.colorContra = 'default';
    }else{
      this.saleMin.status = 'Pendiente';
      this.saleMin.typePayment = 'Contra Entrega';
      this.metadata.colorPagado = 'default';
      this.metadata.colorContra = 'accent';
    }
    console.log(this.saleMin);
    this.getSalesByOrder();
  }

  changeTabSale(ev){
    console.log(ev);
    switch(ev.tab.textLabel){
      case 'Pendiente':
        console.log("pendiente");
        this.saleMin.status = ev.tab.textLabel;
        this.getSales();
      break;
      case 'Pagado':
      console.log("Pagado");
      this.saleMin.status = ev.tab.textLabel;
      break;
      default:
      break;
    }
  }

  /**funcionalidad para obtener las ventas por status
  */
  getSales(){
    this.loading.show(true,"Espere un momento");
    this.session.postRequest("sale:findAllByStatus",this.saleMin).subscribe((data:any)=>{
      this.saleList = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    });
  }

  filtaerTabBySaleAndOrderStatus(ev){
    this.saleMin.delivery = ev.tab.textLabel;
    this.getSalesByOrder();
  }

  /**funcionalidad para obtener las ventas por status y status de ordenes
  */
  getSalesByOrder(){
    this.loading.show(true,"Espere un momento");
    this.session.postRequest("sale:findAllByStatusAndTypePaymentAndDeliveryAndStatusOrder",this.saleMin).subscribe((data:any)=>{
      this.saleList = data.object.instanceList;
      for(let i = 0; i < this.saleList.length; i++){
        this.getPayment(this.saleList[i]);
      }
      this.paginator.total = data.object.total;
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    });
  }

  search(){
    this.saleMin.page = 1;
    this.metadata.searchBoxInput.next(this.saleMin.filter);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.saleMin.max = event.pageSize;
    this.saleMin.page = event.pageIndex + 1;
    // this.getProducts();
  }

  /**funcionalidad para ver el detalle de una ventas por status
  */
  openSale(object){
    const dialogRef = this.dialog.open(AdminSalesSucursalDetailsComponent, {
      width: '60%',
      height: '90%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getSalesByOrder();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  getPayment(object){
    this.session.postRequest("sale:getPaymentType",{id:object.id}).subscribe((data:any)=>{
      if(data.object){
        object.cardType = data.object.CardType;
      }else{
        object.cardType = 'N/A';
      }
    },error=>{
      console.log(error);
    })
  }
}
