<div class="container mb-2">
  <div class="row">
    <div class="col-md-6 col-12 background-black">
      <div class="row">
        <div class="col-6">
          <ul class="m-fo mt-5 mb-5">
            <li class="text-white" style=""><span class="material-icons" style="font-size: 14px;">call</span> Llámanos</li>
            <li class="mt-2">
              <small class="font-weight-light">Suc. Madero<br>951 221 4607</small>
            </li>
            <li class="mt-2">
              <small class="font-weight-light">Suc. Centro<br>951 204 8050</small>
            </li>
            <li class="mt-2">
              <small class="font-weight-light">Suc. 5 señores<br>951 128 0675</small>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <ul class="m-fo mt-5 mb-5 pl-0">
            <li class="text-white" style=""><span class="material-icons" style="font-size: 14px;">email</span> Email</li>
            <li class="mt-2">
              <small class="font-weight-light ml-2">contacto@electricamexicana.com</small>
            </li>
            <li class="mt-3">
              <mat-button-toggle-group #group="matButtonToggleGroup">
                <mat-button-toggle value="left" aria-label="Text align left" href="https://www.facebook.com/electrica.mexicana" style="background-color:transparent;">
                  <img class="logos-redes" src="assets/img/facebook.png">
                </mat-button-toggle>
                <mat-button-toggle value="left" aria-label="Text align left" href="https://twitter.com/ElectricaMex" style="background-color:transparent;">
                  <img class="logos-redes" src="assets/img/twitter.png">
                </mat-button-toggle>
                <mat-button-toggle value="right" aria-label="Text align center" href="https://www.instagram.com/electrica_mexicana50/" style="background-color:transparent;">
                  <img class="logos-redes" src="assets/img/instagram.png">
                </mat-button-toggle>
              </mat-button-toggle-group>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12 background-yellow">
      <div class="row mt-5 mb-5">
        <div class="col-12 input-group-lg">
          <h3 style="color: #fff; text-align: center; font-family: 'Gotham-Medium';" class="mb-5">Entérate de nuestras promociones</h3>
          <input class="form-control mb-3 pb-3 pt-3" type="text" placeholder="Correo electrónico" [(ngModel)]="email" style="text-transform: uppercase;font-size: 12px;letter-spacing: 2px;line-height: 1.4em;">
          <button type="button" name="button" class="btn-white btn-lg btn-block btn pt-3 pb-3" href="https://www.flipsnack.com/ElectricaMexicanaDeAntequera/promociones.html" [disabled]="email">DESCARGAR</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-5 background-gray" style="padding: .5rem; box-shadow: 3px 3px #dddddd;">
  <div class="col-md-8 col-12">
    <div class="row justify-conter-center">
      <div class="col-12 pl-5" style="padding: 0px; text-align: end;">
        <ul class="menu-footer nav ml-5 pl-5">
          <li class="nav-item">
            <a class="nav-link active" href="https://electricamexicana.com.mx/">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://electricamexicana.com.mx/nosotros/">Nosotros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://electricamexicana.com.mx/visitanos/">Visítanos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://electricamexicana.com.mx/atencion-a-proyectos-especiales/">Atención a proyectos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/">Tienda en línea</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://electricamexicana.com.mx/ipem/">IPEM</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://electricamexicana.com.mx/esr-2/">ESR</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center" style="background-color:#171717;">
  <div class="col-md-9 col-12 text-center" >
    <div class="row justify-content-center">
      <div class="col-6 pt-3">
        <div id="footer-info">Eléctrica Mexicana 2020</div>
      </div>
      <div class="col-6 text-right">
        <ul class="nav justify-content-end">
          <li class="nav-item">
            <a class="nav-link" href="https://www.facebook.com/electrica.mexicana" target="_blank">
              <img class="logos-redes-footer" src="assets/img/facebook-footer.png">
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://twitter.com/ElectricaMex" target="_blank">
              <img class="logos-redes-footer" src="assets/img/twitter-footer.png">
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.instagram.com/electrica_mexicana50/" target="_blank">
              <img class="logos-redes-footer" src="assets/img/instagram-footer.png">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
