<app-toolbar></app-toolbar>
<div class="row justify-content-center m-0" style="padding-bottom:60px;">
  <div class="col-10">
    <div class="row mt-2 mb-1 cardProduct">
      <div class="col-12 col-md-6">
        <div class="agotadoband" *ngIf="object.availability == 0">
          AGOTADO
        </div>
          <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
      </div>
      <div class="col-12 col-md-6">
        <!-- ESPAÑOL -->
        <div class="row">
          <div class="col-11" style="padding-bottom:30px;">
            <h2 style="line-height: 2rem;">{{object.name}}</h2>
          </div>
          <div class="col-12" style="text-align: start; padding-bottom:30px;">
            <h3 class="text-azul-fuerte">{{object.price | currency:'MXN':'symbol-narrow'}} &nbsp;<span class="text-naranja iva-incluido">IVA incluido</span></h3>
          </div>
          <div class="col-12 text-justify text-gris pb-2">
            {{object.short_description}}
          </div>
          <div class="col-12 text-justify text-gris">
            {{object.description}}
          </div>

        </div>
          <div class="row" style="margin-top: 30px; padding: 15px; justify-content: flex-start;">
            <div class="col-6 col-lg-4 form-group content-select" style="display: flex;">
              <!-- <label for="exampleFormControlSelect1" style="margin-top: 10px;">CANT</label> -->
              <select class="custom-select" name="quantity" [(ngModel)]="metadata.quantity">
                <option *ngFor="let object of fakeQuantity " [value]="object">{{object}}</option>
              </select>
              <i></i>
            </div>
          </div>
          <div class="row">
            <div class="col-8" style="padding-left: 30px;">
              <button  class="btn-acua" style="text-align:center; padding: 10px 10px 0px 10px; cursor:pointer;" (click)="addProductToShoopingCart();" [disabled]="fakeQuantity.length == 0">
                <img src="/assets/img/icono-carrito-BLC-27.svg" alt="Carrito" width="30" style="padding-bottom:10px;" *ngIf="fakeQuantity.length > 0">
                <p *ngIf="fakeQuantity.length > 0">Añadir</p>
                <p *ngIf="fakeQuantity.length == 0">AGOTADO</p>
              </button>
            </div>
          </div>

      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
