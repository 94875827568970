<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva mensajería</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-10">
        <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.status" style="float:right;">Activado</mat-slide-toggle>
      </div>
      <div class="col-10">
        <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.pickup" style="float:right;">Entrega en sucursal</mat-slide-toggle>
      </div>
      <mat-form-field class="col-12 col-md-8" appearance="outline">
        <mat-label>&nbsp;Nombre</mat-label>
        <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.name" [formControl]="complexForm.controls['name']">
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-4" appearance="outline">
        <mat-label>&nbsp;Precio</mat-label>
        <input matInput placeholder="Precio" name="mPrice" [(ngModel)]="object.price" [formControl]="complexForm.controls['price']">
        <mat-error *ngIf="complexForm.controls['price'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <!-- <mat-form-field class="col-12 col-md-8" appearance="outline">
        <mat-label>&nbsp;Tiempo de envío en (dias)</mat-label>
        <input matInput placeholder="Tiempo de envio en (dias)" name="mtime" [(ngModel)]="object.time">
      </mat-form-field> -->
      <div class="col-10">
        <p>Listado de politicas</p>
        <div class="row" *ngFor="let object of deliveryPoliticList; let i =  index;">
          <mat-form-field appearance="fill" class="col-11">
            <mat-label>Politica</mat-label>
            <textarea matInput name="politic{{i}}" [(ngModel)]="object.politic" ></textarea>
          </mat-form-field>
          <div class="col-1">
            <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Eliminar" (click)="deleteAPolitic(object)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-6">
            <button mat-flat-button color="warn" style="width:100%;" (click)="addPolitic();">Agregar Politica</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
