<app-toolbar></app-toolbar>

<div class="row justify-content-center" style="text-align: justify;">
  <div class="col-12 col-md-8">
<h2>Términos y Condiciones</h2>
<small>Términos y Condiciones de Uso</small>
<br>
<br>

<h4>INFORMACIÓN RELEVANTE</h4>


<p>Es requisito necesario para la compra de productos ofrecidos en <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a>, leer y aceptar los siguientes Términos y Condiciones ya que esto significa que usted navega informado y conscientemente este sitio web.</p>
<p>Todos los productos ofrecidos en nuestra tienda en línea pudieran ser creadas, cobradas, enviadas o presentadas por otra página web ajena a Eléctrica Mexicana y en tal caso estarían sujetas a sus propios Términos y Condiciones. </p>
<p>En algunos casos para adquirir un producto, será necesario el registro de datos personales del usuario así como la definición de una contraseña. El usuario puede elegir y cambiar la contraseña para administrar su cuenta en cualquier momento. <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a>/ no asume la responsabilidad en caso de que el usuario entregue sus datos de acceso a un tercero.</p>
<p>Todas las compras y transacciones que se lleven a cabo por medio de esta tienda en línea, están sujetas a un proceso de confirmación y verificación, el cual podría incluir:</p>

<ul>
  <li>Verificación del stock</li>
  <li>Disponibilidad de producto</li>
  <li>Validación de la forma de pago</li>
  <li>Validación de la factura (en caso de existir) </li>
  <li>Cumplimiento de las condiciones requeridas por el medio de pago seleccionado.</li>
</ul>

<p>En algunos casos puede que se requiera una verificación por medio de correo electrónico.</p>
<p>Las promociones realizadas en la tienda en línea son válidas solamente en las compras realizadas en este sitio web.</p>
<p>Dudas, comentarios o sugerencias póngase en contacto con Eléctrica Mexicana al teléfono 951 226 29 78 o a través del correo electrónico: contacto@electricamexicana.com</p>

<br>
<h4>USO NO AUTORIZADO</h4>
<p>En caso de que aplique, ningún usuario sin autorización por escrito puede colocar imágenes de nuestros productos y la información de descripción, en otro sitio web, tienda en línea o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.</p>
<br>
<h4>PROPIEDAD</h4>
<p>El usuario no puede declarar propiedad intelectual o exclusiva a ninguna de las imágenes que muestran los productos que se comercializan en <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a>. Todos los productos son propiedad de los proveedores. En ningún caso esta compañía será responsable de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.</p>
<br>
<h4>POLÍTICA DE REEMBOLSO Y GARANTÍA</h4>
<p>En el caso de productos que sean mercancías irrevocables no-tangibles, no realizamos reembolsos después de que se envíe el producto, usted tiene la responsabilidad de entender antes de comprarlo. </p>
<p>Le pedimos que lea cuidadosamente antes de comprarlo. Hacemos solamente excepciones con esta regla cuando la descripción no se ajusta al producto. </p>
<p>Hay algunos productos que pudieran tener garantía y posibilidad de reembolso, pero lo anterior será especificado al comprar el producto. En tales casos la garantía solo cubrirá fallas de fábrica y sólo se hará efectiva cuando el producto se haya usado correctamente. </p>
<p>La garantía no cubre averías o daños ocasionados por uso indebido. Los términos de la garantía están asociados a fallas de fabricación y funcionamiento en condiciones normales de los productos y sólo se harán efectivos estos términos si el equipo ha sido usado correctamente. Esto incluye:</p>
<ul>
  <li>De acuerdo a las especificaciones técnicas indicadas para cada producto.</li>
  <li>En condiciones ambientales acorde con las especificaciones indicadas por el fabricante.</li>
  <li>En uso específico para la función con que fue diseñado de fábrica.</li>
  <li>En condiciones de operación eléctricas acorde con las especificaciones y tolerancias indicadas.</li>
</ul>
<br>
<h4>COMPROBACIÓN ANTIFRAUDE</h4>
<p>La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una investigación más rigurosa, para evitar transacciones.</p>
<br>
<h4>PRIVACIDAD</h4>
<p>Este sitio web <a href="https://www.electricamexicana.com" >https://www.electricamexicana.com</a>/ garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por el usuario o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que deba ser revelada en cumplimiento a una orden judicial o requerimientos legales.</p>
<p>La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento de crear su cuenta. Eléctrica Mexicana S.A. de C.V. se reserva los derechos de cambiar o de modificar estos términos sin previo.</p>

  </div>
</div>

<app-footer></app-footer>
