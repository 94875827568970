<app-toolbar></app-toolbar>

<!-- TITULO CARRITO -->
<div class="row justify-content-center m-0" style="">
    <h2 style="padding-top: 35px; font-size: 1.5rem;">PEDIDO</h2>
</div>

<!-- TABLAS DE CARRITO DE COMPRAS !-->

<div class="row justify-content-center m-0" style="padding: 20px 0px;">
  <div class="col-11 col-md-10 col-lg-10">
    <div class="row justify-content-start">
      <div class="col-12 col-md-6" style="margin-top: 10px; padding: 0px 5px;">
        <div style="padding: 10px; padding-top: 0px;">
          <mat-card style="background-color: #fdfdfd;">
            <mat-horizontal-stepper [linear]="true" #stepper>
              <mat-step>
                <ng-template matStepLabel>Envio</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1" style="color: #0275bf;">Selecciona un método de envio.</p>

                  <div class="col-12">
                    <div class="checkDelivery" *ngFor="let object of deliveryListHome" (click)="selectedNewDelivery(object);">
                      <div class="btncheck">
                        <button mat-icon-button>
                          <mat-icon *ngIf="!object.selected">radio_button_unchecked</mat-icon>
                          <mat-icon *ngIf="object.selected">radio_button_checked</mat-icon>
                        </button>

                      </div>
                      <div class="textDelivery">
                        <p>{{object.name}}</p>
                      </div>
                    </div>
                    <div class="col-12 deliveryPoliticss" *ngIf="!metadata.deliveryPliticPickUp">
                      <p style="color: #0275bf;" *ngIf="deliveryPoliticList.length > 0">Politicas de envio.</p>
                        <ul>
                          <li *ngFor="let object of deliveryPoliticList;">{{object.politic}}</li>
                        </ul>
                    </div>
                    <div class="">
                      <p style="color: #0275bf;">Entrega en sucursal</p>
                    </div>
                    <div class="checkDelivery" *ngFor="let object of deliveryListPickUp" (click)="selectedNewDelivery(object);">
                      <div class="btncheck">
                        <button mat-icon-button>
                          <mat-icon *ngIf="!object.selected">radio_button_unchecked</mat-icon>
                          <mat-icon *ngIf="object.selected">radio_button_checked</mat-icon>
                        </button>

                      </div>
                      <div class="textDelivery">
                        <p>{{object.name}}</p>
                      </div>
                    </div>
                    <div class="col-12 deliveryPoliticss" *ngIf="metadata.deliveryPliticPickUp">
                      <p style="color: #0275bf;">Politicas de envio.</p>
                        <ul>
                          <li *ngFor="let object of deliveryPoliticList;">{{object.politic}}</li>
                        </ul>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <button mat-button matStepperNext class="col-6 buttonPayment" [disabled]="object.shipping.delivery == ''">CONTINUAR <span class="material-icons">forward</span></button>
                </div>
              </mat-step>
              <mat-step [stepControl]="firstStepForm" *ngIf="!metadata.deliveryPliticPickUp">
                <ng-template matStepLabel>Datos de envio</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1">Ingresa el correo y número telefonico de contacto.</p>
                  <mat-form-field appearance="outline" class="col-12 col-md-8">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput placeholder="Correo electrónico" name="mEmail_" [(ngModel)]="object.clientEmail" [formControl]="firstStepForm.controls['email']" required>
                    <mat-error *ngIf="firstStepForm.controls['email'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-12 col-md-8">
                    <mat-label>Télefono</mat-label>
                    <input matInput placeholder="Télefono" name="phone" [(ngModel)]="object.clientPhone" [formControl]="firstStepForm.controls['phone']" required>
                    <mat-error *ngIf="firstStepForm.controls['phone'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>

                  <p class="col-12 pb-1 pt-1">Ingrese sus datos</p>
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput name="name" [(ngModel)]="object.clientName" [formControl]="firstStepForm.controls['name']" required>
                    <mat-error *ngIf="firstStepForm.controls['name'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Domicilio de entrega</mat-label>
                    <textarea matInput name="shippingStreet" [(ngModel)]="object.shipping.street" [formControl]="firstStepForm.controls['address']" required></textarea>
                    <mat-error *ngIf="firstStepForm.controls['address'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>Colonia</mat-label>
                    <input matInput name="colony" [(ngModel)]="object.shipping.colony" [formControl]="firstStepForm.controls['colony']" required>
                    <mat-error *ngIf="firstStepForm.controls['colony'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>CP</mat-label>
                    <input matInput name="cp" [(ngModel)]="object.shipping.cp" [formControl]="firstStepForm.controls['cp']" required>
                    <mat-error *ngIf="firstStepForm.controls['cp'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>Ciudad ó Municipio</mat-label>
                    <input matInput name="city" [(ngModel)]="object.shipping.city" [formControl]="firstStepForm.controls['city']" required>
                    <mat-error *ngIf="firstStepForm.controls['city'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>Estado</mat-label>
                    <input matInput name="state" [(ngModel)]="object.shipping.state" [formControl]="firstStepForm.controls['state']" required>
                    <mat-error *ngIf="firstStepForm.controls['state'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Referencias de domicilio</mat-label>
                    <textarea matInput name="referencesShipping"  [(ngModel)]="object.shipping.observations"></textarea>
                  </mat-form-field>
                </div>
                <div class="row">
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <button mat-button matStepperNext class="col-6 buttonPayment" >CONTINUAR <span class="material-icons">forward</span></button>
                </div>
              </mat-step>
              <mat-step [stepControl]="firstBStepForm" *ngIf="metadata.deliveryPliticPickUp">
                <ng-template matStepLabel>Datos de envio</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1">Ingresa el correo y número telefonico de contacto.</p>
                  <mat-form-field appearance="outline" class="col-12 col-md-8">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput placeholder="Correo electrónico" name="mEmail_" [(ngModel)]="object.clientEmail" [formControl]="firstBStepForm.controls['email']" required>
                    <mat-error *ngIf="firstBStepForm.controls['email'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-12 col-md-8">
                    <mat-label>Télefono</mat-label>
                    <input matInput placeholder="Télefono" name="phone" [(ngModel)]="object.clientPhone" [formControl]="firstBStepForm.controls['phone']" required>
                    <mat-error *ngIf="firstBStepForm.controls['phone'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>

                  <p class="col-12 pb-1 pt-1">Ingrese sus datos</p>
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput name="name" [(ngModel)]="object.clientName" [formControl]="firstBStepForm.controls['name']" required>
                    <mat-error *ngIf="firstBStepForm.controls['name'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>

                </div>
                <div class="row">
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <button mat-button matStepperNext class="col-6 buttonPayment" >CONTINUAR <span class="material-icons">forward</span></button>
                </div>
              </mat-step>
              <mat-step [stepControl]="thirdStepFrom">
                <ng-template matStepLabel>Método de pago</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1">Selecciona un método de pago.</p>

                  <div class="col-12">
                    <mat-radio-group aria-label="Select an option" color="warn" name="mpaymentMethod" [(ngModel)]="object.typePayment"  [formControl]="thirdStepFrom.controls['paymentMethod']" required  (ngModelChange)="changePaymentMethod();">
                      <mat-radio-button [value]="object.selected" class="senders" *ngFor="let object of paymentMethods"> <span>{{object.name}}</span><img [src]="object.icon" style="width: 50px;">
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="thirdStepFrom.controls['paymentMethod'].hasError('required')">
                      El campo es <strong>requerido</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="row" *ngIf="object.typePayment == 'Contra Entrega'" >
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <button mat-button matStepperNext class="col-12 buttonPayment" [disabled]="thirdStepFrom.invalid || saleProductsList.length == 0" (click)="sendToPayOnStore();" *ngIf="metadata.deliveryPliticPickUp">PAGA Y RECOGE EN TIENDA</button>
                  <button mat-button matStepperNext class="col-12 buttonPayment" [disabled]="thirdStepFrom.invalid || saleProductsList.length == 0" (click)="sendToPayOnStore();" *ngIf="!metadata.deliveryPliticPickUp">PAGAR AL RECIBIR</button>

                </div>
                <div class="row"  *ngIf="object.typePayment == 'Payworks'">
                  <!-- session de payworks -->
                  <div>
                    <mat-form-field class="col-12" appearance="outline" >
                      <input matInput placeholder="Nombre del Titular" name="name" [(ngModel)]="metadata.name" [formControl]="payworksForm.controls['name']" required>
                      <mat-error *ngIf="payworksForm.controls['name'].hasError('required')">
                        Campo <strong>requerido</strong>.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12" appearance="outline" >
                      <input matInput placeholder="Numero de Tarjeta" name="cardNumber" [(ngModel)]="payworksModel.NUMERO_TARJETA" [formControl]="payworksForm.controls['cardNumber']" required>
                      <mat-error *ngIf="payworksForm.controls['cardNumber'].hasError('required')">
                        Campo <strong>requerido</strong>.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-6" appearance="outline">
                      <mat-select placeholder="Mes" name="exp_month" [(ngModel)]="metadata.exp_month" [formControl]="payworksForm.controls['exp_month']"  required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let object of metadata.months" [value]="object.value">
                          {{object.month}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="payworksForm.controls['exp_month'].hasError('required')">Campo <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-6" appearance="outline">
                      <mat-select placeholder="Año" name="exp_year" [(ngModel)]="metadata.exp_year" [formControl]="payworksForm.controls['exp_year']"  required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let object of metadata.years" [value]="object">
                          {{object}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="payworksForm.controls['exp_year'].hasError('required')">Campo <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-6">
                      <input matInput placeholder="CVC" name="cvcNumber" [(ngModel)]="payworksModel.CODIGO_SEGURIDAD" [formControl]="payworksForm.controls['cvc']" required >
                      <span matSuffix><i class="material-icons">credit_card</i></span>
                      <mat-error *ngIf="payworksForm.controls['cvc'].hasError('required')">
                        Campo <strong>requerido</strong>.
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <button mat-button matStepperNext class="col-12 buttonPayment" [disabled]="payworksForm.invalid || saleProductsList.length == 0" (click)="sendTo3DSecure();">PAGAR</button>

                </div>
              </mat-step>
            </mat-horizontal-stepper>
            <div class="row">
              <div class="col-12" style="text-align:center;">
                <hr>
                <a href="https://store.electricamexicana.com.mx/#/shipping_policies" target="_blank" style="color:rgb(46 163 242)!important;">Politicas de Envío.</a>&nbsp;&nbsp;
                <a href="https://store.electricamexicana.com.mx/#/terms" target="_blank" style="color:rgb(46 163 242)!important;">Términos y Condiciones</a>

              </div>
            </div>
          </mat-card>

        </div>
      </div>

      <div class="col-12 col-md-4" style="margin-top: 10px; padding: 0px 5px;">
        <mat-card >
          <div class="row justify-content-center">
            <div class="col-12">
              <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
                <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
                  <div class="imgTitle">
                    <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #3ab77c; color: #fff; font-weight: 100; font-size: 0.83rem; top: 5px; left:5px;">{{object.quantity}}</span>
                    <div class="imgAvatar">
                      <img src="{{object.product.image}}" alt="{{object.product.name}}">
                    </div>
                  </div>
                  <div class="titlePayment">
                    {{object.product.name}}
                  </div>
                  <div class="priceTitle">
                    {{object.total | currency:'MXN':'symbol-narrow'}}
                  </div>
                </li>
              </ul>
              <hr>
            </div>
            <div class="col-6 col-md-4 col-lg-6" style="margin: 0px; padding: 0px 25px;">
              <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
                <li style="margin-bottom: 15px;">ENVÍO</li>
                <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
              </ul>
            </div>
            <div class="col-6 col-md-4 col-lg-6" style="margin: 0px; padding: 0px 15px; text-align: end;">
              <ul  style="list-style:none; padding: 0px;">
                <li style="margin-bottom: 15px;" *ngIf="metadata.sender != ''">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
                <li style="margin-bottom: 15px;" *ngIf="metadata.sender == ''"><span style="font-size: 0.8571428571em;">seleccione un método de envio.</span></li>
                <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
              </ul>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

  </div>
</div>



<app-footer></app-footer>
