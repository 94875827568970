import { environment } from '../../../environments/environment';
/**url / endpoint para la conexión al backend
* *Si environment.production es true devuelve url de "url_de_producción"
* *Si environment.production es false devuelve url de "url_de_desarrollo"
*
*/
const server_url:string = environment.production ? 'https://www.store.electricamexicana.com.mx/public/api/':'https://www.store.electricamexicana.com.mx/public/api/';
// const server_url:string = environment.production ? 'http://127.0.0.1:8000/api/':'http://127.0.0.1:8000/api/';

export default server_url;
