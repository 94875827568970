<!-- <div class="alert alert-success" role="alert" *ngIf=" object.typePayment == 'Payworks'"> Pagado </div>
<div class="alert alert-danger" role="alert" *ngIf=" object.typePayment == 'Contra Entrega'">Pago pendiente</div> -->

<p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Acontinuación se detallan los datos de la compra.</p>
<ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
  <li>Folio: {{object.folio}}</li>
  <li>Cliente: {{object.clientName}}</li>
  <li>Correo: {{object.clientEmail}}</li>
  <li>Teléfono: {{object.clientPhone}}</li>
</ul>
<p style="padding-top: 15px; font-weight: bold;">Entregar en:</p>
<ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
  <li>Sucursal: {{object.shipping.delivery}}</li>
</ul>
<div *ngIf="object.clientSocialReason != ''" style="background: #d6d6d6;border-radius: 5px;padding: 5px;">
  <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;" *ngIf="object.clientSocialReason != ''">Datos de facturación*</p>
  <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;" *ngIf="object.clientSocialReason != ''">
    <li>Razón Social: {{object.clientSocialReason}}</li>
    <li>RFC: {{object.clientRfc}}</li>
    <li>Email: {{object.clientEmail}}</li>
  </ul>
  <p style="color: #045081; font-size: small;">*El cliente requiere factura.</p>
</div>

<div class="row">
  <div class="col-12">
    <p>Cambie el estatus de la venta.</p>
  </div>

  <mat-form-field appearance="outline" class="col-12">
    <mat-label>Cambiar estatus de pedido</mat-label>
    <mat-select name="mstatusSel" [(ngModel)]="order.status" (ngModelChange)="orderChangeStatus();">
      <mat-option *ngFor="let object of statusList" [value]="object">{{object}}</mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div class="row">
  <div class="col-12">
    <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Listado de productos.</p>
    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Imagen</th>
          <th>Nombre</th>
          <th>Cantidad</th>
          <th>Precio Unit.</th>
          <th>Total</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of saleProductsList; let i = index;">
          <td>
            <div class="imgAvatar">
              <img src="{{object.product.image}}" alt="{{object.product.name}}">
            </div>
          </td>
          <td>{{object.product.name}}</td>
          <td>
            {{object.quantity}}
          </td>
          <td>
            {{object.unit_price | currency:'MXN':'symbol-narrow'}}
          </td>
          <td>
            {{object.total | currency:'MXN':'symbol-narrow'}}
          </td>
        </tr>
      </tbody>
    </table>
    <hr>

  </div>
  <div class="col-6">

  </div>
  <div class="col-3" style="margin: 0px; padding: 0px 25px;">
    <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
      <li style="margin-bottom: 15px;">ENVÍO</li>
      <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
    </ul>
  </div>
  <div class="col-3" style="margin: 0px; padding: 0px 15px; text-align: end;">
    <ul  style="list-style:none; padding: 0px;">
      <li style="margin-bottom: 15px;">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
      <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
    </ul>
  </div>
</div>
<div class="row justify-content-end mt-4">
  <div class="col-4">
    <button mat-flat-button color="warn" style="width:100%;"  (click)="close(true);">Aceptar</button>
    <br>
  </div>
</div>
