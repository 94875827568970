<app-admin-toolbar></app-admin-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Productos</h2>
        </div>
        <div class="col-3 text-right">
          <button mat-button color="primary" routerLink="/admin/product/update/new"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nuevo Producto</button>
        </div>
      </div>
      <div class="row justify-content-end">
        <mat-form-field class="col-12 col-md-6" appearance="fill">
          <input matInput placeholder="Buscar" name="user" [(ngModel)]="productMin.filter" (ngModelChange)="search()">
        </mat-form-field>
      </div>
      <mat-tab-group (selectedTabChange)="changeCategory($event);">
        <mat-tab label="{{object.name}}" *ngFor="let object of categoryList"> </mat-tab>
      </mat-tab-group>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Código</th>
            <th>Existencias</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of list; let i = index;">
            <td>{{object.name}}</td>
            <td>
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Precio</mat-label>
                <input matInput placeholder="Precio" name="price{{i}}" type="number" [(ngModel)]="object.price" (change)="updateProduct(object);">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Cod.</mat-label>
                <input matInput placeholder="Cod." name="code{{i}}"  [(ngModel)]="object.en_name" (change)="updateProduct(object);">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Extencias</mat-label>
                <input matInput placeholder="Existencias" name="availability{{i}}" type="number" [(ngModel)]="object.availability" (change)="updateProduct(object);">
              </mat-form-field>
            </td>
            <td>
              <span *ngIf="object.status" class="labelEnabled">Activado</span>
              <span *ngIf="!object.status" class="labelDisabled">Inactivo</span>
            </td>
            <td>
              <div class="table-actions">
                <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" routerLink="/admin/product/update/{{object.id}}" ><mat-icon>edit</mat-icon></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
