import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../../directive/loading/loading.module';
import { SessionService } from '../../../service/session/session.module';
import * as moment from 'moment';
moment.locale('es-us');


@Component({
  selector: 'app-web-payworks-status',
  templateUrl: './web-payworks-status.component.html',
  styleUrls: ['./web-payworks-status.component.css']
})
export class WebPayworksStatusComponent implements OnInit {

  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['data'] != 'status') {
        console.log(params['data'])
      }else{
        console.log(params['data']);
        this.activatedRoute.queryParams.subscribe(async(params) => {
          console.log(":::::::",params);
        });
      }
    });
  }



}
