import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';


import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { KsfUploadFilesModule } from './directive/ksf-upload-files/ksf-upload-files.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebHomeComponent } from './web/web-home/web-home.component';
import { ToolbarComponent } from './web/web-home/toolbar/toolbar.component';
import { FooterComponent } from './web/web-home/footer/footer.component';
import { WebProductDetailsComponent } from './web/web-home/web-product-details/web-product-details.component';
import { WebShoppingcartComponent } from './web/web-home/web-shoppingcart/web-shoppingcart.component';
import { WebProductosComponent } from './web/web-home/web-productos/web-productos.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminToolbarComponent } from './admin/admin-toolbar/admin-toolbar.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { AdminDeliveryUpdateComponent } from './admin/admin-delivery/admin-delivery-update/admin-delivery-update.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminCategoryUpdateComponent } from './admin/admin-category/admin-category-update/admin-category-update.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { ImageProductDirective } from './admin/admin-product/image-product-directive/image-product.directive';
import { AdminSalesComponent } from './admin/admin-sales/admin-sales.component';
import { AdminSalesDetailsComponent } from './admin/admin-sales/admin-sales-details/admin-sales-details.component';
import { WebPaymentComponent } from './web/web-home/web-payment/web-payment.component';
import { WebSearchMexpagoComponent } from './web/web-home/web-search-mexpago/web-search-mexpago.component';
import { WebTrackOrderComponent } from './web/web-home/web-track-order/web-track-order.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebPayworksStatusComponent } from './web/web-home/web-payworks-status/web-payworks-status.component';
import { WebSendPoliticsComponent } from './web/web-home/web-send-politics/web-send-politics.component';
import { WebTermsComponent } from './web/web-home/web-terms/web-terms.component';
import { ProductConstructionComponent } from './web/web-home/product-construction/product-construction.component';
import { AddFacturaComponent } from './web/web-home/web-search-mexpago/add-factura/add-factura.component';
import { AdminSalesContracargoComponent } from './admin/admin-sales-contracargo/admin-sales-contracargo.component';
import { AdminSalesSucursalComponent } from './admin/admin-sales-sucursal/admin-sales-sucursal.component';
import { AdminSalesSucursalDetailsComponent } from './admin/admin-sales-sucursal/admin-sales-sucursal-details/admin-sales-sucursal-details.component';
import { WebShoppingcartDetailsComponent } from './web/web-home/web-shoppingcart-details/web-shoppingcart-details.component';
import { AdminPvBanorteComponent } from './admin/admin-pv-banorte/admin-pv-banorte.component';
import { AdminPvBanorteDetailsComponent } from './admin/admin-pv-banorte/admin-pv-banorte-details/admin-pv-banorte-details.component';

@NgModule({
  declarations: [
    AppComponent,
    WebHomeComponent,
    ToolbarComponent,
    FooterComponent,
    WebProductDetailsComponent,
    WebShoppingcartComponent,
    WebProductosComponent,
    AdminLoginComponent,
    AdminToolbarComponent,
    AdminDashboardComponent,
    AdminDeliveryComponent,
    AdminDeliveryUpdateComponent,
    AdminCategoryComponent,
    AdminCategoryUpdateComponent,
    AdminProductComponent,
    AdminProductUpdateComponent,
    ImageProductDirective,
    AdminSalesComponent,
    AdminSalesDetailsComponent,
    WebPaymentComponent,
    WebSearchMexpagoComponent,
    WebTrackOrderComponent,
    WebPayworksStatusComponent,
    WebSendPoliticsComponent,
    WebTermsComponent,
    ProductConstructionComponent,
    AddFacturaComponent,
    AdminSalesContracargoComponent,
    AdminSalesSucursalComponent,
    AdminSalesSucursalDetailsComponent,
    WebShoppingcartDetailsComponent,
    AdminPvBanorteComponent,
    AdminPvBanorteDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxGalleryModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
    KsfUploadFilesModule.forRoot(),
    NgbModule,
  ],
  exports:[
    SessionModule,
    LoadingModule,
    KsfUploadFilesModule
  ],
  entryComponents:[
    AdminCategoryUpdateComponent,
    ImageProductDirective,
    AdminProductUpdateComponent,
    AdminSalesDetailsComponent,
    AddFacturaComponent,
    AdminSalesSucursalDetailsComponent,
    WebShoppingcartDetailsComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
