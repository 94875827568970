<app-toolbar></app-toolbar>
<div class="row justify-content-center m-0" style="padding-bottom:90px;">
  <div class="col-11 col-md-10 col-lg-10 pt-3">
    <h2 style="display: inline;">Carrito de Compras</h2>
    <button  class="btn-azul" name="button" style="float: right;" (click)="backClicked();"> REGRESAR</button>
    <div class="row justify-content-start">

      <div class="col-12 col-lg-8" style="margin-top: 30px; padding: 0px 5px;">
        <div style="padding: 10px; padding-top: 0px;">

          <div class="table-prod">
                <table cellspacing="0">
                  <tr style="font-size: 10pt; color: #6ba8d7; border: none;">
                      <th>Articulo</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th></th>
                  </tr>

                  <tr style="" *ngFor="let object of shoopingCart">
                      <td>
                        <div class="row">
                          <div class="col-md-3">
                            <img class="img-product" src="{{object.product.image}}" mat-list-icon  alt="" width="100%">
                          </div>
                          <div class="col-12 col-md-8">
                            <div class="text-gris" style="font-size: 11pt; text-align: left;">{{object.product.name}}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6 class="text-azul-fuerte" style="font-size: 11pt;" >{{object.product.price | currency:'MXN':'symbol-narrow'}}</h6>
                      </td>
                      <td>
                        <div class="input-group" style="width:7.3rem;">
                          <div class="input-group-prepend" style="cursor:pointer;" (click)="changeQuantity('restar',object);">
                            <div class="input-group-text" id="btnGroupAddon">-</div>
                          </div>
                          <input name="quantity" class="form-control" style="text-align: center;" [(ngModel)]="object.quantity" disabled>
                          <div class="input-group-append" style="cursor:pointer;" (click)="changeQuantity('sumar',object);">
                            <div class="input-group-text" id="btnGroupAddon">+</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6 class="text-naranja" style="font-size: 12pt; font-weight: bold;">{{(object.quantity*object.product.price)| currency:'USD':'symbol-narrow'}}</h6>
                      </td>
                      <td>
                        <i class="fa fa-times" aria-hidden="true" style="cursor:pointer; color:red; font-size: larger;" (click)="deleteAProduct(object);"></i>
                      </td>
                  </tr>
              </table>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4" style="margin-top: 10px; padding: 0px 5px;">
        <div class="row justify-content-center">
          <div class="col-12" style="margin: 0px; padding: 0px 25px;">
            <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
              <li style="margin-bottom: 15px; font-size: larger;">Total a pagar</li>
            </ul>
          </div>
          <div class="col-12" style="margin: 0px; padding: 0px 15px; text-align: center;">
            <ul  style="list-style:none; padding: 0px;">
              <li><h6 class="" style="font-size: 12pt; font-weight: bold; color:#6ba8d7;">{{metadata.subtotal | currency:'MXN':'symbol-narrow'}}</h6></li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col-12">
            <button type="button" class="btn-naranja" style="width: 100%;" [disabled]="shoopingCart.length == 0" routerLink="/payment">Ir a pagar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
