import { Component, OnInit, HostListener} from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  shoppingCartQuantity = 0;
  productMin={
    filter:'',
    max: 200,
    page: 1,
  }
  metadata = {
    disabledCp:false,
    search: new Rx.Subject<string>(),
  }
  productListSearch = [];
  public isMenuCollapsed = true;
  constructor(private session:SessionService) {
    this.metadata.search.debounceTime(900)
    .switchMap(val => {
        // console.log('called once',val)
        if(val == ''){
          this.productListSearch = [];
          this.productMin.filter = '';
        }else{
          this.productListSearch = [];
          this.searchProduct();
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
      console.log('error logged:');
      console.log(error);
    });
   }

  ngOnInit(): void {
  }

  ngDoCheck(){
    this.shoppingCartQuantity = localStorage.getItem('carritoLength') ? parseFloat(localStorage.getItem('carritoLength')):0;
  }

  /***función para buscar productos
  */
  searchProduct(){
    this.session.postRequest("product:searching",this.productMin).subscribe((data:any)=>{
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].image = 'https://store.electricamexicana.com.mx/public/uploads/'+data.object.instanceList[i].image;
      }
      this.productListSearch = data.object.instanceList;
    },error=>{
      console.log("error:product:searching",error);
    })
  }
  search(){
    this.productMin.page = 1;
    this.metadata.search.next(this.productMin.filter);
  }
  productSeletedToClean(ev){
    this.productMin.filter = '';
  }

}
