<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Nuevo Producto</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" routerLink="/admin/product">REGRESAR</button>
      </div>
    </div>
    <div class="row justify-content-center">
      <p class="col-12">Ingrese los datos requeridos del formulario.</p>
      <div class="col-10">
        <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.status" style="float:right;">Activado</mat-slide-toggle>
      </div>
      <div class="col-10">
        <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.outstanding" style="float:right;">Producto Destacado</mat-slide-toggle>
      </div>
      <mat-form-field appearance="outline" class="col-10 col-md-8">
        <mat-label>Código.</mat-label>
        <input matInput placeholder="Código." name="code" [formControl]="complexForm.controls['code']" [(ngModel)]="object.en_name" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['code'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10 col-md-8">
        <mat-label>Nombre.</mat-label>
        <input matInput placeholder="Agregar un nombre." name="nombre" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10 col-md-2">
        <mat-label>Precio</mat-label>
        <input matInput placeholder="Precio" name="price" type="number" [formControl]="complexForm.controls['price']" [(ngModel)]="object.price" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['price'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Cantidad a vender</mat-label>
        <input matInput placeholder="Cantidad a vender" name="quantity" [(ngModel)]="object.availability" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Descripción</mat-label>
        <textarea matInput placeholder="Descripción" name="descripcion" [formControl]="complexForm.controls['description']" [(ngModel)]="object.description" required></textarea>
        <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Descripción corta</mat-label>
        <textarea matInput placeholder="Descripción corta" name="short_description"  [(ngModel)]="object.short_description"></textarea>
      </mat-form-field>
      <!-- <hr class="col-11">
      <p class="col-12">INGLES</p>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Agregar un nombre." name="en_name" [(ngModel)]="object.en_name" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Descripción" name="en_descripcion"  [(ngModel)]="object.en_description" required></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Short description</mat-label>
        <textarea matInput placeholder="Short description" name="en_short_description"  [(ngModel)]="object.en_short_description"></textarea>
      </mat-form-field> -->

      <div class="col-10">
        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline" class="col-12 p-0">
              <mat-label>Tags</mat-label>
              <input matInput placeholder="Tags" name="tags" [(ngModel)]="object.tags" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-12 p-0">
              <mat-label>Categoría</mat-label>
              <mat-select name="categoría" [(ngModel)]="object.category_id" [formControl]="complexForm.controls['categoria']" required>
                <mat-option *ngFor="let object of categoryList" [value]="object.id">
                  {{object.description}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="complexForm.controls['categoria'].hasError('required')">
                Campo <strong>requerido</strong>.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 p-0">
          </div>
          <div class="col-10">
            <p>Ingrega las images a utilzar en la plataforma, dale click en <strong>"SUBIR"</strong> y selecciona la <strong>portada principal</strong> de tu producto</p>
            <div *ngFor="let object of imgesList">
              <div class="row">
                <div class="col-1">
                  <button mat-icon-button *ngIf="!object.cover" (click)="coverSelected(object);" style="top:25%;"> <mat-icon>radio_button_unchecked</mat-icon></button>
                  <button mat-icon-button *ngIf="object.cover"  style="top:25%;"> <mat-icon>check_circle_outline</mat-icon></button>
                </div>
                <div class="col-9">
                  <image-product [uploadCode]="'product:upload'" [mFile]="object"></image-product>
                </div>
                <div class="col-1">
                  <button mat-icon-button *ngIf="object.uploadted && object.img.name != ''"  style="top:25%;" (click)="deleteAnImage(object)"> <mat-icon>delete</mat-icon></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-10 mt-2">
            <br>
            <button mat-raised-button color="primary"  class="cardImgButtom" (click)="addImgModel();">Agregar nueva imagen</button>
            <button mat-raised-button color="primary"  class="cardImgButtom" (click)="deleteImgModel();">Quitar una imagen</button>

          </div>
        </div>
      </div>
      <div class="col-10 mt-3">
        <button mat-raised-button color="primary" style="width:100%;"  class="cardImgButtom" (click)="send(true);" [disabled]="complexForm.invalid">Guardar</button>
      </div>
    </div>

  </div>
</div>
