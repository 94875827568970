
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: 'auth:signup',
    endpoint: 'auth/signup'
  },{
    code: 'auth:loginWithFaceboook',
    endpoint: 'auth/loginWithFaceboook'
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "category:list",
    endpoint: "category/list"
  },{
    code: "category:get",
    endpoint: "category/get"
  },{
    code: "category:update",
    endpoint: "category/update"
  },{
    code: "product:list",
    endpoint: "product/list"
  },{
    code: "product:get",
    endpoint: "product/get"
  },{
    code: "product:update",
    endpoint: "product/update"
  },{
    code: "product:upload",
    endpoint: "product/upload"
  },{
    code: "delivery:list",
    endpoint: "delivery/list"
  },{
    code: "delivery:update",
    endpoint: "delivery/update"
  },{
    code: "delivery:get",
    endpoint: "delivery/get"
  },{
    code: "product:addImage",
    endpoint: "product/addImage"
  },{
    code: "product:deleteImage",
    endpoint: "product/deleteImage"
  },{
    code:"product:findAllByStatusAndOutstanding",
    endpoint:"product/findAllByStatusAndOutstanding"
  },{
    code:"product:findAllByStatus",
    endpoint:"product/findAllByStatus"
  },{
    code: "delivery:findAllByStatus",
    endpoint: "delivery/findAllByStatus"
  },{
    code: "sale:update",
    endpoint: "sale/update"
  },{
    code: "sale:findAllByPhone",
    endpoint: "sale/findAllByPhone"
  },{
    code: "sale:findByMexpago",
    endpoint: "sale/findByMexpago"
  },{
    code: "sale:checkAndValidate",
    endpoint: "sale/checkAndValidate"
  },{
    code: "sale:findByPhoneAndFolio",
    endpoint: "sale/findByPhoneAndFolio"
  },{
    code: "order:findBySale",
    endpoint: "order/findBySale"
  },{
    code: "order:findAllStatus",
    endpoint: "order/findAllStatus"
  },{
    code: "order:changeStatus",
    endpoint: "order/changeStatus"
  },{
    code: "sale:findAllByStatus",
    endpoint: "sale/findAllByStatus"
  },{
    code: "sale:findAllByStatusAndStatusOrder",
    endpoint: "sale/findAllByStatusAndStatusOrder"
  },{
    code: "product:findAllByCategory",
    endpoint: "product/findAllByCategory"
  },{
    code: "product:searching",
    endpoint: "product/searching"
  },{
    code: "deliveryPolitic:update",
    endpoint: "deliveryPolitic/update"
  },{
    code: "deliveryPolitic:get",
    endpoint: "deliveryPolitic/get"
  },{
    code: "deliveryPolitic:delete",
    endpoint: "deliveryPolitic/delete"
  },{
    code: "deliveryPolitic:findAllByDelivery",
    endpoint: "deliveryPolitic/findAllByDelivery"
  },{
    code: "product:searchingNew",
    endpoint: "product/searchingNew"
  },{
    code: "product:findAllByNewCategory",
    endpoint:"product/findAllByNewCategory"
  },{
    code: "sale:updateFactura",
    endpoint: "sale/updateFactura"
  },{
    code: "sale:updateStatus",
    endpoint: "sale/updateStatus"
  },{
    code: "sale:get",
    endpoint: "sale/get"
  },{
    code: "sale:findAllByStatusAndTypePaymentAndDeliveryAndStatusOrder",
    endpoint: "sale/findAllByStatusAndTypePaymentAndDeliveryAndStatusOrder"
  },{
    code: "sale:getPaymentType",
    endpoint: "sale/getPaymentType"
  }
];

export default urls;
