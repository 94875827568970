import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-web-shoppingcart-details',
  templateUrl: './web-shoppingcart-details.component.html',
  styleUrls: ['./web-shoppingcart-details.component.css']
})
export class WebShoppingcartDetailsComponent implements OnInit {
  object = {
    id:'',
    name:'',
    price:'',
    short_description:'',
    description:'',
    image:'',
    tags:'',
    category_id:'',
    status:true,
    en_name:'',
    en_short_description:'',
    en_description:'',
    availability:1,
    outstanding:false
  };
  constructor( public dialogRef: MatDialogRef<WebShoppingcartDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar:MatSnackBar) {
  }

  ngOnInit(): void {
    this.object = this.data;
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'category:001';
    }
    this.dialogRef.close(object);
  }
}
