<app-toolbar></app-toolbar>

<!-- TITULO -->
<div class="row justify-content-center m-0" style="">
    <h2 style="padding-top: 35px; font-size: 1.5rem;">Restrea tu pedido.</h2>
</div>
<div class="row justify-content-center m-0" style="padding: 20px 0px;">
  <div class="col-11 col-md-10 col-lg-10">
    <div style="padding: 10px; padding-top: 0px;">
      <div class="row justify-content-center">
        <div class="col-12">
          <p>Ingresa tu número teléfonico y tu folio de compra para restrear tu pedido.</p> <br>
        </div>
        <mat-form-field appearance="outline" class="col-4">
          <mat-label>Teléfono</mat-label>
          <input matInput name="phone" [(ngModel)]="object.clientPhone" [formControl]="firstStepForm.controls['phone']" required>
          <mat-error *ngIf="firstStepForm.controls['phone'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-4">
          <mat-label>Folio</mat-label>
          <input matInput name="folio" [(ngModel)]="object.folio" [formControl]="firstStepForm.controls['folio']" required>
          <mat-error *ngIf="firstStepForm.controls['folio'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
        <button mat-button color="accent" class="col-3" [disabled]="firstStepForm.invalid" (click)="findSaleByPhoneAndFolio();">Buscar</button>
      </div>
      <mat-card style="background-color: #fdfdfd;" *ngIf="metadata.msj != ''">
        <div class="alert alert-danger" role="alert" > {{metadata.msj}} </div>
      </mat-card>
      <mat-card style="background-color: #fdfdfd;" *ngIf="object.id != ''">
        <div class="alert alert-success" role="alert" *ngIf=" object.status == 'Pagado'"> Pagado </div>
        <div class="alert alert-danger" role="alert" *ngIf=" object.status == 'Pendiente'">Pago pendiente</div>
        <h4>Hola {{object.clientName}},</h4>
        <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Acontinuación se detallan los datos de tu compra.</p>
        <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
          <li>Folio: {{object.folio}}</li>
          <li>Cliente: {{object.clientName}}</li>
          <li>Correo: {{object.clientEmail}}</li>
          <li>Teléfono: {{object.clientPhone}}</li>
        </ul>
        <br>
        <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Datos de envio</p>
        <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
          <li>Direccion: {{object.shipping.street + ', '+object.shipping.colony + ', '+object.shipping.city}}</li>
          <li>Estado: {{object.shipping.state}}</li>
          <li>Código Postal: {{object.shipping.cp}}</li>
          <li>Mensajeria: {{object.shipping.delivery}}</li>
        </ul>
        <br>
        <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Proceso de envio</p>
        <p *ngIf="order.guide != ''">* La siguiente guia se agrego a tu pedido #{{object.folio}}</p>
        <ul style="list-style:none; padding: 0px; font-size:14pt; color:#6f6f6f;" *ngIf="order.guide != ''">
          <li># Guia de rastreo:{{order.guide}}</li>
          <li> </li>
        </ul>
        <div class="container">
        <ul class="progressbar">
              <li  [ngClass]="object.selected ? 'active' : ''" *ngFor =" let object of statusList" style="cursor:pointer;" (click)="selectedOrderStatus(object);">{{object.status}}</li>
        </ul>
        </div>
        <br>
        <div *ngIf="metadata.orderStatusSelected.description != ''">
          <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
            <li>Estatus: {{metadata.orderStatusSelected.status}}</li>
            <li>Fecha: {{metadata.orderStatusSelected.date | date:'dd-MM-yyyy hh:mm'}}</li>
            <li>{{metadata.orderStatusSelected.description}}</li>
          </ul>
        </div>
        <div class="row">
          <div class="col-6">
            <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Listado de productos.</p>
            <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
              <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
                <div class="imgTitle">
                  <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #3ab77c; color: #fff; font-weight: 100; font-size: 0.83rem; top: 5px; left:5px;">{{object.quantity}}</span>
                  <div class="imgAvatar">
                    <img src="{{object.product.image}}" alt="{{object.product.name}}">
                  </div>
                </div>
                <div class="titlePayment">
                  {{object.product.name}}
                </div>
                <div class="priceTitle">
                  {{object.total | currency:'MXN':'symbol-narrow'}}
                </div>
              </li>
            </ul>
            <hr>

          </div>
          <div class="col-6">

          </div>
          <div class="col-3" style="margin: 0px; padding: 0px 25px;">
            <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
              <li style="margin-bottom: 15px;">ENVÍO</li>
              <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
            </ul>
          </div>
          <div class="col-3" style="margin: 0px; padding: 0px 15px; text-align: end;">
            <ul  style="list-style:none; padding: 0px;">
              <li style="margin-bottom: 15px;">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
              <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
            </ul>
          </div>
        </div>
      </mat-card>

    </div>
  </div>
  <!-- <div class="col-11 col-md-10 col-lg-10" *ngIf="saleList.length > 0">
    <mat-card style="background-color: #fdfdfd;">
      <h4>OTROS PEDIDOS</h4> <br>
      <div class="table-prod">
            <table cellspacing="0" style="width:100%">
              <tr style="font-size: 10pt; color: #54080b; border-bottom: groove;">
                  <th>FOLIO</th>
                  <th>CLIENTE</th>
                  <th>TELEFONO</th>
                  <th>MENSAJERIA</th>
                  <th>ESTATUS</th>
                  <th></th>
              </tr>

              <tr style="" *ngFor="let object of saleList;">
                  <td>
                    {{object.folio}}
                  </td>
                  <td>
                    {{object.clientName}}
                  </td>
                  <td>
                    {{object.clientPhone}}
                  </td>
                  <td>
                    {{object.shipping.delivery}}
                  </td>
                  <td>
                  </td>
                  <td>
                    <button mat-icon-button color="" style="top:-5px; color: #ed171f;" (click)="selectedsale(object);">
                      <mat-icon>description</mat-icon>
                    </button>
                  </td>
              </tr>
          </table>
      </div>
    </mat-card>


  </div> -->
</div>




<app-footer></app-footer>
