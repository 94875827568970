import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StorageTool } from '../../service/session/storage.tool';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
moment.locale('es-us');

@Component({
  selector: 'app-admin-pv-banorte',
  templateUrl: './admin-pv-banorte.component.html',
  styleUrls: ['./admin-pv-banorte.component.css']
})
export class AdminPvBanorteComponent implements OnInit {
  @ViewChild('myForm') ngForm;
  payworksForm: FormGroup;

  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'Centro',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'9512262978',
    clientEmail:'contacto@electricamexicana.com',
    platform:'web',
    typePayment:'Payworks',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: true,
    privacy:true,
    emailfactura:'',
    deliverytext:'Centro',
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[]
  };

  saleProductsModel = {
    id:'',
    quantity:1,
    unit_price:0,
    discount:0,
    tax:0,
    subtotal:0,
    total:0,
    sale_id:'',
    product_id:210,
    product:{}
  };
  payworksModel = {
    'MONTO':'0.00',
    'REFERENCIA':'', //Únicamente es requerida para las siguientes transacciones: POSTAUTORIZACION DEVOLUCION CANCELACION
    'NUMERO_TARJETA':'',
    'FECHA_EXP':'', // Formayo MMAA
    'CODIGO_SEGURIDAD':'',
    'REF_CLIENTE1':'',
    'CONTROL_NUMBER':'',
  };
  secureObject = {
    Card:'',
    Expires:'',
    Total:'',
    CardType:'',
    Reference3D:''
  };
  securePayworksModel = {
    id:'',
    Expires:'',
    Total:'',
    CardType:'',
    Reference3D:'',
    sale_id:'',
    control:'',
    status:''
  };
  metadata = {
    sender:'',
    deliveryPliticPickUp:false,
    language:'spanish',
    months: [],
    years: [],
    name: '',
    exp_year:'',
    exp_month:'',
    message:'',
    codigo:'',
    transaction:''
  }
  object = JSON.parse(JSON.stringify(this.saleModel));
  shipinsList = [];

  constructor(
    public session:SessionService,
    public loading:LoadingService,
    public fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar:MatSnackBar,
    public dialog: MatDialog,
    public storage: StorageTool
  ) {
    this.payworksForm = fb.group({
      'price': [null, Validators.compose([Validators.required])],
      'name': [null, Validators.compose([Validators.required])],
      'cardNumber': [null, Validators.compose([Validators.required])],
      'exp_year': [null, Validators.required],
      'exp_month': [null, Validators.required],
      'cvc': [null, Validators.required],
    });
    this.object.saleProducts.push(this.saleProductsModel);
    this.object.payment = JSON.parse(JSON.stringify(this.securePayworksModel));
   }

  ngOnInit(): void {
    this.metadata.months = this.getMonths();
    this.metadata.years = this.getYear();
    this.loadBranch();
  }

  /*funcionalidad para calcular el total
  */
  calculateTotals(){
    this.object.tax = 0;
    this.object.subtotal = 0;
    this.object.total = 0;
    for(let i = 0 ; i < this.object.saleProducts.length; i++){
      this.object.saleProducts[i].discount = 0;
      this.object.saleProducts[i].subtotal = (this.object.saleProducts[i].quantity * this.object.saleProducts[i].unit_price)/1.16;
      this.object.saleProducts[i].tax = this.object.saleProducts[i].subtotal * 0.16;
      this.object.saleProducts[i].total = this.object.saleProducts[i].subtotal + this.object.saleProducts[i].tax;
      // realiza sumatoria de totales
      this.object.subtotal = parseFloat(this.object.subtotal) + parseFloat(this.object.saleProducts[i].total);
    }
    this.object.subtotal = (this.object.subtotal + this.object.delivery - this.object.discount) / 1.16;
    this.object.tax = this.object.subtotal * 0.16;
    this.object.total = this.object.subtotal + this.object.tax;
    console.log("sale",this.object);
  }

  async sendTo3DSecure(){
    // this.loading.show(true,"Espere un momento...");
    this.calculateTotals();
    this.object.clientName = this.metadata.name;
    this.object.clientSocialReason = this.metadata.name;
    this.object.total = this.object.total * 1;
    this.object.total = this.object.total.toFixed(2);
    let str = this.metadata.exp_year.toString();
    // completamos el objeto 3DSecure
    this.secureObject.Card = this.payworksModel.NUMERO_TARJETA;
    this.secureObject.CardType = this.getCardType(this.payworksModel.NUMERO_TARJETA);
    this.secureObject.Expires = this.metadata.exp_month.toString()+'/'+str.slice(str.length-2, str.length);
    this.secureObject.Reference3D = this.guid();
    this.payworksModel.CONTROL_NUMBER = this.guid();
    this.secureObject.Reference3D = this.secureObject.Reference3D.toUpperCase()
    this.secureObject.Total = this.object.total;
    this.payworksModel.FECHA_EXP = this.metadata.exp_month.toString()+str.slice(str.length-2, str.length);
    this.loading.show(true,'Procesando su pago...');
    //Guardamos la venta el sistema para recueperar despues
    this.securePayworksModel.CardType = this.secureObject.CardType;
    this.securePayworksModel.Expires = this.secureObject.Expires;
    this.securePayworksModel.Reference3D = this.secureObject.Reference3D;
    this.securePayworksModel.status =  "Pendiente";
    this.securePayworksModel.Total = this.secureObject.Total ;
    this.securePayworksModel.control = this.payworksModel.CONTROL_NUMBER;
    this.object.payment = JSON.parse(JSON.stringify(this.securePayworksModel));
    // console.log(this.object);
    let sale:any = await this.sendSale();
    // console.log("https://store.electricamexicana.com.mx/banorte/processpv.php?Card=" +  this.secureObject.Card+'&Expires='+this.secureObject.Expires+'&CardType='+this.secureObject.CardType+'&Reference3D='+this.secureObject.Reference3D+'&Total='+this.secureObject.Total+'&AMOUNT='+this.object.total+'&CARD_EXP='+this.payworksModel.FECHA_EXP+'&SECURITY_CODE='+this.payworksModel.CODIGO_SEGURIDAD+'&CONTROL_NUMBER='+this.payworksModel.CONTROL_NUMBER)
    window.location.href = "https://store.electricamexicana.com.mx/banorte/processpv.php?Card=" +  this.secureObject.Card+'&Expires='+this.secureObject.Expires+'&CardType='+this.secureObject.CardType+'&Reference3D='+this.secureObject.Reference3D+'&Total='+this.secureObject.Total+'&AMOUNT='+this.object.total+'&CARD_EXP='+this.payworksModel.FECHA_EXP+'&SECURITY_CODE='+this.payworksModel.CODIGO_SEGURIDAD+'&CONTROL_NUMBER='+this.payworksModel.CONTROL_NUMBER;
  }


  /** función para enviar lso datos de venta al backend
  */
  sendSale(){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("sale:update",this.object).subscribe((data:any)=>{
        resolve(data.object);
      },error=>{
        this.loading.hide();
        reject(error);
      })
    })
  }


  /**Utilidades
  */

  getCardType(object){
    let value = '';
    // console.log(object[0]);
    switch(object[0]) {
       case '4':
       case 4: {
          value = 'VISA'
          break;
       }
      case '5':
       case 5: {
          value = 'MC'
          break;
       }
       default: {
          //statements;
          value = ''
          break;
       }
     }
     return value;
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + s4() + s4();
  }

  getMonths(): any {
    return ([{ value: '01', month: 'Enero' }, { value: '02', month: 'Febrero' }, { value: '03', month: 'Marzo' }, { value: '04', month: 'Abril' }, { value: '05', month: 'Mayo' }, { value: '06', month: 'Junio' }, { value: '07', month: 'Julio' }, { value: '08', month: 'Agosto' }, { value: '09', month: 'Septiembre' }, { value: '10', month: 'Octubre' }, { value: '11', month: 'Noviembre' }, { value: '12', month: 'Diciembre' }]);
  }
  getYear(): any {
    let now = new Date().getFullYear();
    let years = [];
    for (var i = 0; i < 10; i++) {
      var yearTemp = now + i;
      years.push(yearTemp);
    }
    return years;
  }

  loadBranch(){
    let aux = JSON.parse(JSON.stringify(this.shippingModel));
    aux.cp = '68000';
    aux.street = "Calzada, Francisco I. Madero 240";
    aux.colony ="Centro";
    aux.city = "Oaxaca de Juárez";
    aux.state = "Oaxaca";
    aux.delivery = 'Madero';
    this.shipinsList.push(aux);
    let auxM = JSON.parse(JSON.stringify(this.shippingModel));
    auxM.cp = '68000';
    auxM.street = "Fray Bartolome de las Casas no. 308";
    auxM.colony ="Centro";
    auxM.city = "Oaxaca de Juárez";
    auxM.state = "Oaxaca";
    auxM.delivery = 'Centro';
    this.shipinsList.push(auxM);
    let auxC = JSON.parse(JSON.stringify(this.shippingModel));
    auxC.cp = '68129';
    auxC.street = "Av. Ferrocarril 117, FERROCARRIL";
    auxC.colony ="Fernando Gomez Sandoval";
    auxC.city = "Santa Lucía del Camino";
    auxC.state = "Oaxaca";
    auxC.delivery = '5 Señores';
    this.shipinsList.push(auxC);
  }

  shippingSelected(object){
    this.object.shipping = JSON.parse(JSON.stringify(object));
    this.object.deliverytext = object.delivery;
  }

}
