import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../../directive/loading/loading.module';
import { SessionService } from '../../../service/session/session.module';
import { AddFacturaComponent } from './add-factura/add-factura.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as moment from 'moment';
moment.locale('es-us');

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';


@Component({
  selector: 'app-web-search-mexpago',
  templateUrl: './web-search-mexpago.component.html',
  styleUrls: ['./web-search-mexpago.component.css']
})
export class WebSearchMexpagoComponent implements OnInit {

  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: false,
    privacy:false,
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[]
  };
  saleList = [];
  object = JSON.parse(JSON.stringify(this.saleModel));
  payworks = {
    control:'',
    codigo:'',
    message:'',
    reference:'',
    transaction:''
  }

  metadata = {
    status:'',
    payworks:false,
    message:'',
    control:''
  }
  saleProductsList = [];

  constructor(protected session: SessionService,
    public loading: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['phone'] != 'mexpago') {
        if(params['phone'] == 'payworks'){
          console.log(params['data']);
          this.activatedRoute.queryParams.subscribe(async(params) => {
            console.log(":::::::",params);
            if(params.transaction == 'bad'){
              this.metadata.payworks = true;
              this.metadata.control = params.control;
              this.metadata.message = params.message;
            }else{
              this.metadata.payworks = false;
              this.payworks.codigo = params.codigo;
              this.payworks.control = params.control;
              this.payworks.message = params.message;
              this.payworks.reference = params.reference;
              this.payworks.transaction = params.transaction;
              this.getSaleByMexpago();
            }

          });
        }else{
          this.getSale(params['phone']);
        }
      }else{
        console.log(params['phone']);
      }
    });
    console.log(this.object);
  }

  /**funcion para obtener las refervaciones por numero telefonico
  */
  getSale(id){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("sale:get",{id:id}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      if(this.object.emailfactura == ''){
        this.updateFactura();
      }
      this.getProductlist();
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

  updateFactura(){
    const dialogRef = this.dialog.open(AddFacturaComponent, {
      width: '50%',
      height:'90%',
      disableClose:true,
      data: this.object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /**función para seleccionar una reservación
  */
  selectedsale(object){
    this.object = JSON.parse(JSON.stringify(object));
    this.getProductlist();
  }

  /**función para obtener reservacion por mexpago
  */
  getSaleByMexpago(){
    this.loading.show(true,"Espere un momento...");

    this.session.postRequestAnonimus("sale:findByMexpago",this.payworks).subscribe((data:any)=>{
      //actualizamos el pago
      this.object = data.object;
      this.getProductlist();
      let status = '';
      if(this.payworks.transaction == 'ok'){
        status = 'Pagado';
      }else{
        status = 'Cancelado';
      }
      this.session.postRequestAnonimus("sale:checkAndValidate",{
        id:this.object.id,
        paymentId:this.object.paymentId,
        status:status
      }).subscribe((data:any)=>{
        //actualizamos el pago
        this.object.status = data.object.status;
        // verificamos si el usuario va a requerir factura
        if(this.object.emailfactura == ''){
          this.updateFactura();
        }
        this.loading.hide();
      },error=>{
        console.log(error);
        this.loading.hide();
      })
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }
  /**funcionalidad para obetenr el producto
  */
  getProduct(object){
    this.session.postRequest("product:get",{id:object.product_id}).subscribe((data:any)=>{
      object.product = data.object;
      object.product.image = 'https://store.electricamexicana.com.mx/public/uploads/'+object.product.image;
    },error=>{
      console.log(error);
    })
  }
  /**metodo para obetner los productos
  */
  getProductlist(){
    for(let i = 0; i < this.object.products.length; i++){
      this.getProduct(this.object.products[i]);
    }
    this.saleProductsList = this.object.products;
    console.log(this.saleProductsList);
  }

  finished(){
    this.router.navigate(['/']);
  }

  onPrint(){
      window.print();
  }

  downloadImage(){
    this.loading.show(true,"Espere un momento...");
    var node = document.getElementById('printTicket');
    htmlToImage.toPng(node)
      .then( (dataUrl)=> {
        this.loading.hide();
        const a = document.createElement('a');
                  a.href = dataUrl;
                  a.download = 'comprobante.png';
                  a.click();
      })
      .catch( (error)=> {
        console.error('oops, something went wrong!', error);
        this.loading.hide();
      });
  }

}
