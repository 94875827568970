import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StorageTool } from '../../../service/session/storage.tool';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
moment.locale('es-us');
declare var paypal;

@Component({
  selector: 'app-web-payment',
  templateUrl: './web-payment.component.html',
  styleUrls: ['./web-payment.component.css']
})
export class WebPaymentComponent implements OnInit {

  @ViewChild('myForm') ngForm;
  secondStepFrom: FormGroup;
  thirdStepFrom: FormGroup;
  firstStepForm: FormGroup;
  payworksForm: FormGroup;
  firstBStepForm:FormGroup;
  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: true,
    privacy:true,
    emailfactura:'',
    deliverytext:'',
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[]
  };

  saleProductsModel = {
    id:'',
    quantity:1,
    unit_price:0,
    discount:0,
    tax:0,
    subtotal:0,
    total:0,
    sale_id:'',
    product_id:'',
    product:{}
  };

  paypalModel = {
    id:'',
    platform:'',
    product_name:'',
    paypal_id:'',
    intent:'',
    state:'',
    environment:'',
    sale_id:''
  }
  mexpagoModel = {
    // url:'https://mexpago.com/app/pagoOnline',
    url:'https://dev.mexpago.com/app/pagoOnline',
    monto:0,
    noTransaccion:'',
    llave:'',
    fecha:'',
    articulos:'',
    precargaDatos:'',
    enviarCorreo:false,
    infoComercio:true,
    lenguaje:'es',
  }
  mexpagoDB = {
    id:'',
    folioMexPago:'',
    numeroTransaccion:'',
    pago:'',
    monto:''
  }
  payworksModel = {
    'MONTO':'0.00',
    'REFERENCIA':'', //Únicamente es requerida para las siguientes transacciones: POSTAUTORIZACION DEVOLUCION CANCELACION
    'NUMERO_TARJETA':'',
    'FECHA_EXP':'', // Formayo MMAA
    'CODIGO_SEGURIDAD':'',
    'REF_CLIENTE1':'',
    'CONTROL_NUMBER':'',
  };
  secureObject = {
    Card:'',
    Expires:'',
    Total:'',
    CardType:'',
    Reference3D:''
  };
  securePayworksModel = {
    id:'',
    Expires:'',
    Total:'',
    CardType:'',
    Reference3D:'',
    sale_id:'',
    control:'',
    status:''
  };
  paymentMethods = [{
    icon:'/assets/img/tarjeta.png',
    name:'Tarjeta de Débito/Crédito',
    selected:'Payworks'
  },{
    icon:'/assets/img/logo2.png',
    name:'Pago contra entrega',
    selected:'Contra Entrega'
  }];
  object = JSON.parse(JSON.stringify(this.saleModel));
  deliveryMin = {
    max: 100,
    page: 1,
    status:true
  }
  deliveryList = [];
  saleProductsList = [];
  metadata = {
    sender:'',
    deliveryPliticPickUp:false,
    language:'spanish',
    months: [],
    years: [],
    name: '',
    exp_year:'',
    exp_month:'',
    message:'',
    codigo:'',
    transaction:''
  }
    deliveryPoliticList = [];
    deliveryListPickUp = [];
    deliveryListHome = [];
  constructor(
    public session:SessionService,
    public loading:LoadingService,
    public fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar:MatSnackBar,
    public dialog: MatDialog,
    public storage: StorageTool
  ) {
    this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
      if(data){
        this.metadata.language = data;
      }
    })
    this.firstStepForm = fb.group({
      'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.required],
      'name': [null, Validators.required],
      'address': [null, Validators.required],
      'colony': [null, Validators.required],
      'cp': [null, Validators.required],
      'city': [null, Validators.compose([Validators.required])],
      'state': [null, Validators.required],
    });
    this.firstBStepForm = fb.group({
      'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.required],
      'name': [null, Validators.required],
    });
    this.secondStepFrom = fb.group({
      'sender': [null, Validators.compose([Validators.required])],
    });
    this.thirdStepFrom = fb.group({
      'paymentMethod': [null, Validators.compose([Validators.required])],
    });
    this.payworksForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'cardNumber': [null, Validators.compose([Validators.required])],
      'exp_year': [null, Validators.required],
      'exp_month': [null, Validators.required],
      'cvc': [null, Validators.required],
    });

  }

  ngOnInit(): void {
    this.saleProductsList = [];
    this.getDeliveries();
    this.metadata.months = this.getMonths();
    this.metadata.years = this.getYear();
    //cargamos los productos del carrito de compras
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      if(data){
        for(let item of data){
          let aux = JSON.parse(JSON.stringify(this.saleProductsModel));
          aux.quantity = item.quantity;
          aux.unit_price = item.product.price;
          aux.product_id = item.product.id;
          aux.product = item.product;
          this.saleProductsList.push(aux);
        }
        this.calculateTotals();
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  /*funcionalidad para seleccionar una mensajeria
  */
  deliverySelected(){
    for(let item of this.deliveryList){
      if(this.metadata.sender == item.id){
        if(!item.pickup){
          if(this.object.total >= 1000){
            this.object.delivery = 0;
          }else{
            this.object.delivery = parseFloat(item.price);
          }
        }else{
          this.object.delivery = parseFloat(item.price);
        }
        this.object.shipping.delivery = item.name;
        this.getPoliticsByDeliver();
      }
    }
    this.calculateTotals();
  }
  selectedNewDelivery(object){
    for(let i = 0; i < this.deliveryListHome.length; i++){
      this.deliveryListHome[i].selected = false;
    }
    for(let i = 0; i < this.deliveryListPickUp.length; i++){
      this.deliveryListPickUp[i].selected = false;
    }
    object.selected = true;
    this.metadata.deliveryPliticPickUp = JSON.parse(JSON.stringify(object.pickup));
    this.metadata.sender = object.id;
    this.deliverySelected();
  }

  /*funcionalidad para calcular el total
  */
  calculateTotals(){
    this.object.tax = 0;
    this.object.subtotal = 0;
    this.object.total = 0;
    for(let i = 0 ; i < this.saleProductsList.length; i++){
      this.saleProductsList[i].discount = 0;
      this.saleProductsList[i].subtotal = (this.saleProductsList[i].quantity * this.saleProductsList[i].unit_price)/1.16;
      this.saleProductsList[i].tax = this.saleProductsList[i].subtotal * 0.16;
      this.saleProductsList[i].total = this.saleProductsList[i].subtotal + this.saleProductsList[i].tax;
      // realiza sumatoria de totales
      this.object.subtotal = parseFloat(this.object.subtotal) + parseFloat(this.saleProductsList[i].total);
    }
    this.object.subtotal = (this.object.subtotal + this.object.delivery - this.object.discount) / 1.16;
    this.object.tax = this.object.subtotal * 0.16;
    this.object.total = this.object.subtotal + this.object.tax;
    // console.log("sale",this.object);
    // console.log("productSale",this.saleProductsList);

  }


  /**función para obtener el listado de mensajeria
  */
  getDeliveries(){
    this.session.postRequestAnonimus("delivery:findAllByStatus",this.deliveryMin).subscribe((data:any)=>{
      this.deliveryList = data.object.instanceList;
      for(let item of this.deliveryList){
        if(item.pickup){
          this.deliveryListPickUp.push(item);
        }else{
          this.deliveryListHome.push(item);
        }
      }
    },error=>{
      console.log("error:delivery:findAllByStatus",error);
    })
  }

  /************ Funciones de Pagos *******************/
    /** función para cambiar el methodo de pago
    */
   async changePaymentMethod(){
     //completamos los datos de productos para enviarlos a la bd
     for(let item of this.saleProductsList){
       let aux = JSON.parse(JSON.stringify(item));
       delete aux.product;
       this.object.saleProducts.push(aux);
     }
      switch(this.object.typePayment){
        case 'Payworks':{
          this.object.payment = JSON.parse(JSON.stringify(this.securePayworksModel));
          this.object.status = "Pendiente";
          break;
        }
        case 'Contra Entrega':{
          this.object.payment = {};
          this.shippingModel
          this.object.shipping.cp = this.object.shipping.cp == '' ?  '68000' : this.object.shipping.cp ;
          this.object.status = "Pendiente";
        }
        default:{
          console.log("Error, selecciona un metodo de pago");
          break;
        }
      }
    }

    /** función para enviar lso datos de venta al backend
    */
    sendSale(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("sale:update",this.object).subscribe((data:any)=>{
          resolve(data.object);
        },error=>{
          this.loading.hide();
          reject(error);
        })
      })
    }

    /**fucnionalidad para enviar venta de mexpago
    */
    async sendToPay(){
      this.loading.show(true,"Espere un momento...");
      let sale:any = await this.sendSale();
      this.storage.remove('shoppingCart');
      // console.log("Mexpago",this.object);
      this.loading.hide();
      this.ngForm.nativeElement.submit();
    }

    /***funcionalidad para pagar cuando se entregue el producto
    */
    async sendToPayOnStore(){
      this.loading.show(true,"Espere un momento...");
      let sale:any = await this.sendSale();
      this.storage.remove('shoppingCart');
      localStorage.setItem('carritoLength', '0');
      this.loading.hide();
      this.router.navigate(['/client/search/'+sale.id]);
      console.log("Contra Entrega",this.object);
    }


    /**Recursos Payworks
    **/
    async sendTo3DSecure(){
      this.loading.show(true,"Espere un momento...");
      this.object.total = this.object.total * 1;
      this.object.total = this.object.total.toFixed(2);
      let str = this.metadata.exp_year.toString();
      // completamos el objeto 3DSecure
      this.secureObject.Card = this.payworksModel.NUMERO_TARJETA;
      this.secureObject.CardType = this.getCardType(this.payworksModel.NUMERO_TARJETA);
      this.secureObject.Expires = this.metadata.exp_month.toString()+'/'+str.slice(str.length-2, str.length);
      this.secureObject.Reference3D = this.guid();
      this.payworksModel.CONTROL_NUMBER = this.guid();
      this.secureObject.Reference3D = this.secureObject.Reference3D.toUpperCase()
      this.secureObject.Total = this.object.total;
      this.payworksModel.FECHA_EXP = this.metadata.exp_month.toString()+str.slice(str.length-2, str.length);
      // this.loading.show(true,'Procesando su pago...');
      //Guardamos la venta el sistema para recueperar despues
      this.securePayworksModel.CardType = this.secureObject.CardType;
      this.securePayworksModel.Expires = this.secureObject.Expires;
      this.securePayworksModel.Reference3D = this.secureObject.Reference3D;
      this.securePayworksModel.status =  "Pendiente";
      this.securePayworksModel.Total = this.secureObject.Total ;
      this.securePayworksModel.control = this.payworksModel.CONTROL_NUMBER;
      this.object.payment = JSON.parse(JSON.stringify(this.securePayworksModel));
      // console.log(this.object);
      let sale:any = await this.sendSale();
      this.storage.remove('shoppingCart');
      localStorage.setItem('carritoLength', '0');
      // console.log("https://store.electricamexicana.com.mx/banorte/process.php?Card=" +  this.secureObject.Card+'&Expires='+this.secureObject.Expires+'&CardType='+this.secureObject.CardType+'&Reference3D='+this.secureObject.Reference3D+'&Total='+this.secureObject.Total+'&AMOUNT='+this.object.total+'&CARD_EXP='+this.payworksModel.FECHA_EXP+'&SECURITY_CODE='+this.payworksModel.CODIGO_SEGURIDAD+'&CONTROL_NUMBER='+this.payworksModel.CONTROL_NUMBER)
      window.location.href = "https://store.electricamexicana.com.mx/banorte/process.php?Card=" +  this.secureObject.Card+'&Expires='+this.secureObject.Expires+'&CardType='+this.secureObject.CardType+'&Reference3D='+this.secureObject.Reference3D+'&Total='+this.secureObject.Total+'&AMOUNT='+this.object.total+'&CARD_EXP='+this.payworksModel.FECHA_EXP+'&SECURITY_CODE='+this.payworksModel.CODIGO_SEGURIDAD+'&CONTROL_NUMBER='+this.payworksModel.CONTROL_NUMBER;
    }

    getCardType(object){
      let value = '';
      // console.log(object[0]);
      switch(object[0]) {
         case '4':
         case 4: {
            value = 'VISA'
            break;
         }
        case '5':
         case 5: {
            value = 'MC'
            break;
         }
         default: {
            //statements;
            value = ''
            break;
         }
       }
       return value;
    }

    guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + s4() + s4();
    }

    getMonths(): any {
      return ([{ value: '01', month: 'Enero' }, { value: '02', month: 'Febrero' }, { value: '03', month: 'Marzo' }, { value: '04', month: 'Abril' }, { value: '05', month: 'Mayo' }, { value: '06', month: 'Junio' }, { value: '07', month: 'Julio' }, { value: '08', month: 'Agosto' }, { value: '09', month: 'Septiembre' }, { value: '10', month: 'Octubre' }, { value: '11', month: 'Noviembre' }, { value: '12', month: 'Diciembre' }]);
    }
    getYear(): any {
      let now = new Date().getFullYear();
      let years = [];
      for (var i = 0; i < 8; i++) {
        var yearTemp = now + i;
        years.push(yearTemp);
      }
      return years;
    }
    /**
    Fin recursos Payworks
    **/

    /**funcionalidad para obtener las politicas de envios por delivery
    */
    getPoliticsByDeliver(){
      this.session.postRequestAnonimus("deliveryPolitic:findAllByDelivery",{delivery:{id:this.metadata.sender}}).subscribe((data:any)=>{
        this.deliveryPoliticList = data.object.instanceList;
      },error=>{
        console.log(error);
      })
    }
}
