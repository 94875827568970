<div class="row">
  <div class="col-11">
    <p>¿Requiere factura?</p>
    <mat-radio-group style="width:100%;" aria-label="Requiere factura" color="warn" name="mSender" [(ngModel)]="metadata.factura"  [formControl]="firstStepForm.controls['factura']" required (change)="facturaSelected();">
      <mat-radio-button value="No" class="senders">No
      </mat-radio-button>
      <mat-radio-button value="Si" class="senders"> Si
      </mat-radio-button>
    </mat-radio-group>
    <mat-error *ngIf="firstStepForm.controls['factura'].hasError('required')">
      El campo es <strong>requerido</strong>
    </mat-error>
    <div class="" *ngIf="metadata.factura == 'Si'">
      <p>Para concluir ingresa la siguiente información:</p>
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Razón Social</mat-label>
        <input matInput name="name" [(ngModel)]="sale.clientSocialReason" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>RFC</mat-label>
        <input matInput name="name" [(ngModel)]="sale.clientRfc" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>Email</mat-label>
        <input matInput name="name" [(ngModel)]="sale.emailfactura" required>
      </mat-form-field>
    </div>
    <p style="color:#ff9015;" class="mt-1">*Solo puedes solicitar factura el mismo día de tu compra</p>
  </div>

</div>
<div class="row justify-content-end mt-4">
  <div class="col-6">
    <button mat-flat-button color="warn" style="width:100%;" [disabled]=" firstStepForm.invalid" (click)="send();">Confirmar</button>
  </div>
</div>
