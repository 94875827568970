import { Component, OnInit,ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { StorageTool } from '../../../service/session/storage.tool';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WebShoppingcartDetailsComponent } from '../web-shoppingcart-details/web-shoppingcart-details.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-web-productos',
  templateUrl: './web-productos.component.html',
  styleUrls: ['./web-productos.component.css']
})
export class WebProductosComponent implements OnInit {
  panelOpenState=false;
  productMin = {
    status: true,
    order:'',
    max:20,
    page:1,
    category:{
      id:''
    }
  };
  list = [];
  metadata ={
    language:'spanish',
    getMoreProducts:true,
  }
  imagesCategoy = [{
    name:'ELECTRICIDAD',
    enabled:'/assets/img/home-electricidad.png',
    disabled:'/assets/img/home-electricidad.png',
    category:{id:''},
    seleted:false
  },{
    name:'PLOMERÍA',
    enabled:'/assets/img/home-plomeria.png',
    disabled:'/assets/img/home-plomeria.png',
    category:{id:''},
    seleted:false
  },{
    name:'ILUMINACIÓN',
    enabled:'/assets/img/home-iluminacion.png',
    disabled:'/assets/img/home-iluminacion.png',
    category:{id:''},
    seleted:false
  },{
    name:'HERRAMIENTA',
    enabled:'/assets/img/home-herramientas.png',
    disabled:'/assets/img/home-herramientas.png',
    category:{id:''},
    seleted:false
  }];

  categoryMin = {
    max: 100,
    page: 1,
  }
  categories = [];

  constructor(public dialog: MatDialog, private session:SessionService, public loading:LoadingService, public storage: StorageTool,
    private router:Router,
    public activatedRoute:ActivatedRoute,
    public snackBar: MatSnackBar,
  ) {
    //cragamos el idioma configurado
    this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
      if(data){
        this.metadata.language = data;
      }
    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async(params: Params)=>{
      if(params['id'] == 'more'){
        await this.getCategories();
        this.categorySelected(this.imagesCategoy[0].category.id);
      }else{
        await this.getCategories();
        this.categorySelected(params['id']);
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /**funcionalidad para obtener el lsuatdo de productos
  */
  getProducts(){
    if(this.productMin.category.id == ''){
      this.loading.show(true,"Espere un momento...")
      this.session.postRequestAnonimus("product:findAllByStatus",this.productMin).subscribe((data:any)=>{
        console.log(data);
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].image = 'https://store.electricamexicana.com.mx/public/uploads/'+data.object.instanceList[i].image;
        }
        let categoryEqual = false;
        if(data.object.instanceList.length > 0){
          if(data.object.instanceList[0].category.id == this.productMin.category.id){
            categoryEqual = true
          }
        }
        if(categoryEqual){
          this.list = this.list.concat(data.object.instanceList);
        }
        if(data.object.instanceList.length == 0){
          this.metadata.getMoreProducts = false;
        }else{
          this.metadata.getMoreProducts = true;
        }
        this.loading.hide();
      },error=>{
        console.log("product:findAllByStatus:error",error);
        this.loading.hide();
      })
    }else{
      this.loading.show(true,"Espere un momento...")
      this.session.postRequestAnonimus("product:findAllByCategory",this.productMin).subscribe((data:any)=>{
        console.log(data);
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].image = 'https://store.electricamexicana.com.mx/public/uploads/'+data.object.instanceList[i].image;
        }
        this.list = this.list.concat(data.object.instanceList);
        if(data.object.instanceList.length == 0){
          this.metadata.getMoreProducts = false;
        }else{
          this.metadata.getMoreProducts = true;
        }
        this.loading.hide();
      },error=>{
        console.log("product:findAllByStatus:error",error);
        this.loading.hide();
      })
    }
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // console.log("Menu",this.menu.nativeElement.offsetTop);
    // console.log("Altura Window",event.target.scrollingElement.clientHeight);
    // console.log("Event",event);
    // console.log("Event",event.target.scrollingElement.scrollTop);
    if( event.target.scrollingElement.scrollTop > (event.target.scrollingElement.clientHeight/50)){
      if(this.metadata.getMoreProducts){
        this.productMin.page++;
        this.metadata.getMoreProducts = false;
        this.getProducts();
      }
    }else{
      console.log("No hacemos nada");
    }
  }


  /**función para obtener el listado de categorias
  */
  getCategories(){
    return new Promise((resolve,reject)=>{
      this.session.postRequestAnonimus("category:list",this.categoryMin).subscribe(async(data:any)=>{
        this.categories = data.object.instanceList;
        for(let item of this.categories){
          for(let i = 0; i < this.imagesCategoy.length; i++){
            if(item.name == this.imagesCategoy[i].name){
              this.imagesCategoy[i].category = item;
            }
          }
        }
        resolve(true);
      },error=>{
        console.log("error:category:list",error);
        reject(error);
      })
    })
  }

  /**función para seleccionar una categoria y cargar sus prodcutos
  */
  categorySelected(categoryId){
    this.productMin.page = 1;
    this.list = [];
    for(let i=0; i < this.imagesCategoy.length; i++){
      this.imagesCategoy[i].seleted = false;
      if(this.imagesCategoy[i].category.id == categoryId){
        this.imagesCategoy[i].seleted = true;
      }
    }
    this.productMin.category.id = categoryId;
    this.router.navigate(['/productos/'+categoryId]);
    this.getProducts();
  }


  /*funcionalidad para agregar un producto al carrito de compras
  */
  addProductToShoopingCart(object){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      let found = false
      for(let i = 0 ; i < shoppingCart.length; i++){
        if(shoppingCart[i].product.id == object.id){
          found = true;
          shoppingCart[i].quantity++;
        }
      }
      if(!found){
        shoppingCart.push({
          quantity:1,
          product:object
        });
      }

      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.snackBar.open('Listo, este producto se agrego a tu carrito.', 'Aceptar', {duration: 5000});
        this.viewShoopingCart(object);
      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

  viewShoopingCart(object){
    const dialogRef = this.dialog.open(WebShoppingcartDetailsComponent, {
      width: '50%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
        }else{
          // El modal se cerro sin objeto
          // this.snackBar.open('No se guardo ningun registro', '', {
          //   duration: 1000
          // });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        // this.snackBar.open('No se guardo ningun registro', '', {
        //   duration: 1000
        // });
      }
    });
  }

}
