import { Component, OnInit,ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { StorageTool } from '../../../service/session/storage.tool';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-product-construction',
  templateUrl: './product-construction.component.html',
  styleUrls: ['./product-construction.component.css']
})
export class ProductConstructionComponent implements OnInit {
  metadata ={
    language:'spanish',
    getMoreProducts:true
  }
  imagesCategoy = [{
    name:'ELECTRICIDAD',
    enabled:'/assets/img/home-electricidad.png',
    disabled:'/assets/img/home-electricidad.png',
    category:{id:''},
    seleted:false
  },{
    name:'PLOMERÍA',
    enabled:'/assets/img/home-plomeria.png',
    disabled:'/assets/img/home-plomeria.png',
    category:{id:''},
    seleted:false
  },{
    name:'ILUMINACIÓN',
    enabled:'/assets/img/home-iluminacion.png',
    disabled:'/assets/img/home-iluminacion.png',
    category:{id:''},
    seleted:false
  },{
    name:'HERRAMIENTA',
    enabled:'/assets/img/home-herramientas.png',
    disabled:'/assets/img/home-herramientas.png',
    category:{id:''},
    seleted:false
  }];
  categoryMin = {
    max: 100,
    page: 1,
  }
  productMin ={
    status: true,
    outstanding:true,
    max:20,
    page:1
  }
  categories = [];
  products = [];

  constructor(public dialog: MatDialog, private session:SessionService, public loading:LoadingService, public storage: StorageTool,
    private router:Router,
    public activatedRoute:ActivatedRoute,) {
      //cragamos el idioma configurado
      this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
        if(data){
          this.metadata.language = data;
        }
      })
    }

  ngOnInit(): void {
    this.getCategories();
    this.getProducts();
  }

  /**función para obtener el listado de categorias
  */
  getCategories(){
    this.session.postRequestAnonimus("category:list",this.categoryMin).subscribe((data:any)=>{
      this.categories = data.object.instanceList;
      for(let item of this.categories){
        for(let i = 0; i < this.imagesCategoy.length; i++){
          if(item.name == this.imagesCategoy[i].name){
            this.imagesCategoy[i].category = item;
          }
        }
      }
    },error=>{
      console.log("error:category:list",error);
    })
  }

  /**funcionalidad para obtener el lsuatdo de productos
  */
  getProducts(){
    this.loading.show(true,"Espere un momento...")
    this.session.postRequestAnonimus("product:findAllByStatusAndOutstanding",this.productMin).subscribe((data:any)=>{
      // console.log(data);
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].image = 'https://store.electricamexicana.com.mx/public/uploads/'+data.object.instanceList[i].image;
      }
      this.products = data.object.instanceList;
      this.loading.hide();
    },error=>{
      console.log("product:findAllByStatusAndOutstanding:error",error);
      this.loading.hide();
    })
  }


  categorySelected(id){
    this.router.navigate(['/productos/'+id]);
  }


}
