<div class="row background-blue justify-content-center">
  <div class="col-md-11 col-12">
    <ul class="nav justify-content-end">
      <li class="nav-item">
        <a class="nav-link" href="https://twitter.com/ElectricaMex" target="_blank">
          <img class="logos-redes-footer" src="assets/img/twitter.png">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.facebook.com/electrica.mexicana" target="_blank">
          <img class="logos-redes" src="assets/img/facebook.png">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://electricamexicana.com.mx" target="_blank">
          <img class="logos-redes" src="assets/img/instagram.png">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/cart">
          <img class="logos-redes" src="/assets/img/shopping-cart.png" alt="" style="cursor:pointer;" >
          <span class="num-car ml-1">{{shoppingCartQuantity}} elementos</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<nav class="nav navbar-expand-lg menu-background row justify-content-center">
  <div class="col-md-3 col-6" style="padding: 0px; text-align: right;">
    <img src="/assets/img/logo.png" alt="" style="width: 58%;">
  </div>
  <button class="navbar-toggler col-6 text-right mt-3" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" [attr.aria-expanded]="!isMenuCollapsed" aria-controls="collapseExample" aria-expanded="false" aria-label="Toggle navigation">
    <span class="material-icons">dehaze</span>
  </button>
  <div class="collapse navbar-collapse justify-content-center" [ngbCollapse]="isMenuCollapsed">
    <div class="col-md-9 col-12" style="padding: 0px; text-align: center;">
      <ul class="menu">
        <li >
          <a href="https://electricamexicana.com.mx/" class="text-primary">Inicio</a>
        </li>
        <li>
          <a href="https://electricamexicana.com.mx/nosotros/">Nosotros</a>
        </li>
        <li>
          <a href="https://electricamexicana.com.mx/visitanos/">Visítanos</a>
        </li>
        <li>
          <a href="https://electricamexicana.com.mx/visitanos/">Atención a proyectos</a>
        </li>
        <li>
          <a routerLink="/">Tienda en línea</a>
        </li>
        <li>
          <a href="https://electricamexicana.com.mx/ipem/">IPEM</a>
        </li>
        <li>
          <a href="https://electricamexicana.com.mx/esr-2/">ESR</a>
        </li>
        <li>
          <a href="https://electricamexicana.com.mx/cotizar/">Cotizar</a>
        </li>
      </ul>
    </div>
  </div>

</nav>
<div class="container">
  <div class="row">
    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Busca por nombre de producto o código</mat-label>
      <input matInput placeholder="Busca por nombre de producto o código" [(ngModel)]="productMin.filter" (ngModelChange)="search();" [matAutocomplete]="autoProduct">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-autocomplete autoActiveFirstOption #autoProduct="matAutocomplete" (optionSelected)="productSeletedToClean($event);">
      <mat-option *ngFor="let object of productListSearch" [value]="object.id" routerLink="/product/detail/{{object.id}}">
        <img class="example-option-img" aria-hidden src="{{object.image}}" height="25">
        <span>{{object.name}} <strong>{{object.price | currency:'MXN':'symbol-narrow'}}</strong></span>
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
