import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import * as Rx from 'rxjs/Rx';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-product',
  templateUrl: './admin-product.component.html',
  styleUrls: ['./admin-product.component.css']
})
export class AdminProductComponent implements OnInit {
  productMin = {
    filter:'',
    max: 10,
    page: 1,
    category:{
      id:''
    }
  }

  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;

  metadata = {
    search: new Rx.Subject<string>(),
  }

  categoryMin = {
    max: 100,
    page: 1,
  }
  categoryList = [];


  constructor(private session: SessionService,
    public loadding:LoadingService,
    public snackBar: MatSnackBar,
  ) {
      this.metadata.search.debounceTime(700)
      .switchMap(val => {
          console.log('called once',val)
          if(val == ''){
            this.list = [];
            this.productMin.filter = '';
          }else{
            this.list = [];
          }
          this.getProducts();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
        console.log('error logged:');
        console.log(error);
      });
    }

    ngOnInit(): void {
      this.getCategories();
      this.getProducts();
    }

    /**función para obtener el listado de categorias
    */
    getCategories(){
      this.session.postRequest("category:list",this.categoryMin).subscribe((data:any)=>{
        this.categoryList = data.object.instanceList;
      },error=>{
        console.log("error:category:list",error);
      })
    }

      /**función para obtener el listado de categorias
      */
      getProducts(){
        this.loadding.show(true,"Espere un momento...");
        if(this.productMin.filter == ''){
          this.session.postRequest("product:findAllByNewCategory",this.productMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:product:findAllByNewCategory",error);
            this.loadding.hide();
          })
        }else{
          this.session.postRequest("product:searchingNew",this.productMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
            this.loadding.hide();
          },error=>{
            console.log("error:product:list",error);
            this.loadding.hide();
          })
        }

      }

      changeCategory(ev){
        console.log(ev);
        this.productMin.page = 1;
        for(let item of this.categoryList){
          if(item.name == ev.tab.textLabel){
            this.productMin.category = item;
          }
        }
        this.getProducts();
      }

      search(){
        this.productMin.page = 1;
        this.metadata.search.next(this.productMin.filter);
      }

      // funcion para obtener los datos del paginado.
      onPaginateChange(event){
        this.productMin.max = event.pageSize;
        this.productMin.page = event.pageIndex + 1;
        this.getProducts();
      }

      /**funcionalidad para actualizar el precio de un producto
      */
      updateProduct(object){
        console.log(object);
        this.loadding.show(true,"Espere un momento...")
        object.category_id = object.category.id;
        this.session.postRequest("product:update",object).subscribe(async(data:any)=>{
          this.snackBar.open('Registro actualizado...', 'Aceptar', {duration: 5000});
          this.loadding.hide();
        },error=>{
          console.log("error:product:update",error);
          this.loadding.hide();
        });
      }

}
