<app-admin-toolbar></app-admin-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Pedidos a sucursales</h2>
        </div>
        <div class="col-3 text-right">
        </div>
      </div>
      <div class="col-12">
        <mat-chip-list class="mat-chip-list" aria-label="Color selection">
          <mat-chip [color]="metadata.colorPagado"  selected (click)="chipSelected('Pagado')">
            PAGADOS
          </mat-chip>
          <mat-chip [color]="metadata.colorContra"  selected (click)="chipSelected('Contra Cargo')">
            CONTRA CARGOS
          </mat-chip>
        </mat-chip-list>
      </div>
      <mat-tab-group mat-align-tabs="start" (selectedTabChange)="filtaerTabBySaleAndOrderStatus($event);">
        <mat-tab label="{{object.name}}" *ngFor="let object of deliverylist"></mat-tab>
      </mat-tab-group>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Folio</th>
            <th>Cliente</th>
            <th>Telefono</th>
            <th>Mensajeria</th>
            <th>Factura</th>
            <th>Tarjeta</th>
            <th>Monto</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of saleList; let i = index;">
            <td>{{object.folio}}</td>
            <td>{{object.clientName}}</td>
            <td>{{object.clientPhone}}</td>
            <td>{{object.shipping.delivery}}</td>
            <td>
              <span *ngIf="object.clientSocialReason != ''">Si</span>
              <span *ngIf="object.clientSocialReason == ''">No</span>
            </td>
            <td>
              <span *ngIf="object.cardType != ''">{{object.cardType}}</span>
              <span *ngIf="!object.cardType">N/A</span>
            </td>
            <td>{{object.total | currency:'CAD':'symbol-narrow' }}</td>
            <td>
              <span class="labelEnabled">{{object.status}}</span>
            </td>
            <td>
              <button mat-icon-button color="accent"  style="font-size: smaller;" matTooltip="Detalles" (click)="openSale(object);"><mat-icon>chrome_reader_mode</mat-icon></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
