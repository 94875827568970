<app-toolbar></app-toolbar>


<!--TIPOS-->

<div class="row justify-content-center" style="margin: 2rem 0rem;">
  <div class="col-2 rubro" style="text-align: center; cursor:pointer;" *ngFor="let object of imagesCategoy"  (click)="categorySelected(object.category.id);">
    <img class="img-tipo"src="{{object.enabled}}" alt="{{object.name}}" *ngIf="object.seleted">
    <img class="img-tipo"src="{{object.disabled}}" alt="{{object.name}}" *ngIf="!object.seleted">
    <p class="txt-tipo">{{object.name}}</p>
  </div>
  <div class="col-md-2 col-6 rubro" style="text-align: center; cursor:pointer;" routerLink="/product/construction">
    <img class="img-tipo"src="/assets/img/home-construccion.png" alt="construccion" >
    <p class="txt-tipo">CONSTRUCCIÓN</p>
  </div>
</div>

<!--Construcción-->
<div class="container">
  <div class="row justify-content-star">
    <div class="col-12 pl-0 mt-1" style="padding: 2rem 1rem;">
      <img class="w-100"src="/assets/img/construccion.jpeg" alt="construccion">
    </div>
    <!--aca termina la tarjeta-->
  </div>
</div>

<app-footer></app-footer>
